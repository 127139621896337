import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import _ from 'lodash'
import { Views } from 'react-big-calendar'
import moment from 'moment'
import asyncMapLimit from 'async/mapLimit'

import config from '../../config'

// Utils
import history from '../../utils/history'
import { isWindows, buildPlaceholderEntry, buildCurrentEditableEntry } from '../../utils/appUtils'
import eventUtils from '../../utils/eventUtils'
import timeUtils from '../../utils/timeUtils'
import * as projectUtils from '../../utils/projectUtils'
import { CsvEntryBuilder } from '../../server/utils/csvBuilder'
import { logError, log } from '../../utils/logger'
import {
  deleteUnhandledEntriesForDate,
  getUnhandledEntriesForDate,
  getCsvInfoTotal,
  getProject,
} from '../../App/utils'
import {
  getDurationInHours,
  convertDateToLocalTimezone,
  parseUTCStartDate,
  getUTCStartEndISOForTheDay,
} from '../../server/utils/date'
import { addEventDataToEntry } from '../../server/funnel/funnelUtils'

// Components
import PrivateRoute from '../PrivateRoute'
import OfflineModeMessage from '../OfflineModeMessage'

// Views
import Auth from '../../views/Auth'
import WelcomeScreen from '../../views/WelcomeScreen'
import ReportsPage from '../../views/reports'
import Calendar from '../../views/Calendar'
import Graph from '../../views/graph'
import Assignments from '../../views/assignments'
import ErrorScreen from '../../views/ErrorScreen'

// Modals
import ConfirmationModal from '../ConfirmationModal'
import CsvMultiModal from '../CsvMultiModal'
import IssueReportFromMenu from '../IssueReportFromMenu'
import IssueReportFromSentry from '../IssueReportFromSentry'
import DelegateAccessFromMenu from '../DelegateAccess/DelegateAccessFromMenu'

// Models
import Email from '../../server/models/email'
import Event from '../../server/models/event'
import Timesheet from '../../server/models/timesheet'
import TimeEntry from '../../server/models/timeEntry'
import TimeOffEntry from '../../server/models/timeOffEntry'
import Project from '../../server/models/project'
import EntryAction from '../../server/models/entryAction'
import UnhandledEntry from '../../server/models/unhandledEntry'
import Assignment from '../../server/models/assignment'

import {
  CREATE,
  UPDATE,
  DELETE,
  isAnOperation,
  doesOperationFallInsideDateRange,
  getAction,
  getSourceId,
  getEntry,
} from '../../models/entryOperation'

// Entities
import * as userSettingsService from '../../entities/userSettings/service'
import * as timeEntryService from '../../entities/timeEntry/service'

// Update Functions
import { updateUnhandledEntries } from '../DataSync/updateFunc'

// Actions
import {
  handleSafeExecEntryAction,
  removeEntryFromQueue,
  convertToDeleteAction,
  updateLocalEntry,
} from '../../server/actions/entryActionActions'
import { transformDataToValidEntry } from '../../server/actions/helpers/timeEntryActionHelper'
import {
  submitTimesheet,
  reopenTimesheet,
  updateTimesheetApproveState,
} from '../../server/actions/timesheetAction'

// Constants
import { APPROVED, OPEN } from '../../constants/timesheetStatus'
import { CREATION_WAY, REFERENCE_TYPE } from '../../constants/timeEntry'
import {
  UNASSIGNED_PROJECT,
  UNASSIGNED_PROJECT_REPLICON_ID,
  UNASSIGNED_PROJECT_ID,
} from '../../constants/projects'
import { initialState } from '../../App/constants'
import { FUNNEL_ENTRIES_RESOURCE_ID } from '../../constants/events'

import ipcRenderer from '../../ipcRenderer'
import { Approvals } from '../../views/Approvals'
import WorkInProgressPage from '../../views/WorkInProgress/WorkInProgressPage'
import LeadView from '../../views/LeadView'

const { IS_WEB, IS_DELEGATE_ENABLED, IS_GRAPH_ENABLED, IS_ASSIGNMENTS_ENABLED } = config
const { DAY: VIEW_PERIOD_DAY, WEEK: VIEW_PERIOD_WEEK } = Views

class Application extends React.Component {
  projectSelectorRef = React.createRef()

  handleModalClose = () => {
    this.props.handleAppState({
      modal: {
        ...this.props.modal,
        open: false,
      },
    })
  }

  renderModalDialog = () => {
    return (
      <ConfirmationModal
        isOpen={this.props.modal.open}
        actionButtons={[
          {
            handler: this.handleModalClose,
            label: 'ok',
          },
        ]}
        title={this.props.modal.title}
        subtitle={this.props.modal.message}
      />
    )
  }

  clodeNoFreeGapModal = () => this.props.handleAppState({ isNoFreeGapModalOpen: false })

  renderNoFreeGapModal = () => {
    return (
      <ConfirmationModal
        isOpen={this.props.isNoFreeGapModalOpen}
        title="This timesheet has exceeded the number of hours allowed for a day. It is not possible to create more time entries at the time."
        titleColor="inherit"
        actionButtons={[
          {
            handler: this.clodeNoFreeGapModal,
            color: 'primary',
            label: 'Ok',
          },
        ]}
      />
    )
  }

  setHasUserGotStarted = async () => {
    this.props.handleAppState({
      hasUserGotStarted: true,
    })
  }

  renderWelcomeScreen = () => {
    return (
      <WelcomeScreen
        sendErrorMessage={() => {}}
        numberOfInitialSyncRetries={this.props.numberOfInitialSyncRetries}
        setHasUserGotStarted={this.setHasUserGotStarted}
        handleClickRetry={this.props.handleInitialSyncRetry}
        initialSync={this.props.initialSync}
        startInitialSync={this.props.startInitialSync}
      />
    )
  }

  renderErrorScreen = () => {
    return <ErrorScreen />
  }

  getUsedExchangeEvents = () => {
    const usedExchangeEvents = eventUtils.buildUsedExchangeEvents(
      _.concat(
        this.props.events,
        this.props.timeEntries,
        this.props.localEntries.map(e => e.data),
      ),
    )
    return usedExchangeEvents
  }

  clearCurrentEditableEntryProject = cb => {
    this.props.handleAppState(
      prevState => ({
        currentEditableEntry: {
          ...prevState.currentEditableEntry,
          originalProjectId: null,
          type: undefined,
        },
      }),
      cb,
    )
  }

  openAddAllEntriesConfirmationModal = (isButton = false) =>
    this.props.handleAppState({
      isAddAllEntriesFromEventsModalOpen: true,
      isAllEventsButton: isButton,
    })

  getSelectedDayTimesheet = () => {
    return eventUtils.getTimesheetForDay(this.props.selectedDate, this.props.timesheets || [])
  }

  hasTimesheetUnassignedEntries = () => {
    const selectedTimesheet = this.getSelectedDayTimesheet()
    const todayEntries = eventUtils.getSelectedDayEntries(selectedTimesheet, this.props.timeEntries)
    return todayEntries.some(eventUtils.isUnassignedEntryProject)
  }

  getWeekTimesheets = () => {
    return eventUtils.getWeekTimesheets(this.props.selectedDate, this.props.timesheets)
  }

  hasWeekTimesheetsUnassignedEntries = () => {
    const weekTimesheetsRepliconIds = _.map(this.getWeekTimesheets(), timesheet =>
      _.get(timesheet, 'repliconId'),
    )
    const weekEntries = this.props.timeEntries.filter(e =>
      weekTimesheetsRepliconIds.includes(e.repliconTimesheetId),
    )
    return weekEntries.some(eventUtils.isUnassignedEntryProject)
  }

  areAllTimesheetsSubmitted = () => {
    return _.every(this.getWeekTimesheets(), timesheet => timesheet.approvalStatus === APPROVED)
  }

  handleModalOpen = ({ message, title = '' }) => {
    this.props.handleAppState({
      modal: {
        open: true,
        message,
        title,
      },
    })
  }

  readFile = f => {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.readAsText(f)
      reader.onload = e => resolve(e.target.result)
    })
  }

  handleUpload = async event => {
    try {
      const { files } = event.target
      if (!(files && files.length > 0)) return

      this.props.handleAppState({ csvUploadData: { isCSVUploadInProgress: true } })
      const file = files[0]
      const text = await this.readFile(file)

      const projects = await Project.toArray()
      const entryBuilder = new CsvEntryBuilder(text, projects, {
        client: this.props.client,
        selectedDelegateId: this.props.selectedDelegateId,
      })
      const result = await entryBuilder.build()

      if (!result.isOk()) {
        logError(result.getPrettyErrors(), 'handleUploadHandledErrors')
        return
      }

      const csvEntries = []
      const csvsToUploadTotal = result.entries.length + result.errors.failedEntries.length
      this.props.handleAppState({
        csvUploadData: {
          ...this.props.csvUploadData,
          csvsToUploadTotal,
        },
      })

      for (const [index, entry] of result.entries.entries()) {
        this.props.handleAppState({
          csvUploadData: {
            ...this.props.csvUploadData,
            csvUploadCount: index + 1,
          },
        })
        const csvEntry = await this.handleSafeCreateEntry({
          ...entry,
          creationWay: CREATION_WAY.CSV,
        })
        if (csvEntry) csvEntries.push(csvEntry)
      }

      const uploadResult = {
        successCsvInfoTotal: getCsvInfoTotal(csvEntries),
        failedCsvInfoTotal: getCsvInfoTotal(result.errors.failedEntries),
        csvUploadData: {
          ...this.props.csvUploadData,
          isCSVUploadInProgress: false,
        },
      }

      this.props.handleAppState(uploadResult)
    } catch (e) {
      this.props.handleAppState({ csvUploadData: { isCSVUploadInProgress: false } })
      logError(e, 'App > handleUpload > catch > e')
    }
  }

  handleReloadApp = async () => {
    if (IS_WEB) {
      window.location.reload()
      return
    }
    ipcRenderer.send('reset-app')
  }

  isDayViewPeriodSelected = () => {
    return this.props.viewPeriod === VIEW_PERIOD_DAY
  }

  isTimesheetLocked = () => {
    const selectedTimesheet = this.getSelectedDayTimesheet()

    const areDayEventsLocked =
      selectedTimesheet &&
      selectedTimesheet.approvalStatus === APPROVED &&
      this.isDayViewPeriodSelected()

    const areWeekEventsLocked = !this.isDayViewPeriodSelected() && this.areAllTimesheetsSubmitted()

    const areThereUnhandleEntries = !_.isEmpty(this.props.unhandledEntries)

    const timesheetDoesntExist =
      selectedTimesheet && _.isEqual(selectedTimesheet, {}) && this.isDayViewPeriodSelected()

    return (
      areDayEventsLocked || areThereUnhandleEntries || areWeekEventsLocked || timesheetDoesntExist
    )
  }

  getTimeEntriesEvents = () => {
    const { events, timeEntries, localEntries, unhandledEntries } = this.props
    return _.concat(events, timeEntries, localEntries, unhandledEntries)
  }

  handleUpdateTimesheetsInProgressStatus = isTimesheetLoading => {
    this.props.handleAppState({ isTimesheetLoading })
  }

  focusProjectSelector = () => {
    const projectSelector = _.get(this.projectSelectorRef, 'current', null)
    if (projectSelector) {
      projectSelector.focus()
    }
  }

  handleCalendarSelect = async (selectedDate, cb = () => {}) => {
    const displayingDate = timeUtils.correctTodayHours(selectedDate)
    console.log('HERE')
    this.handleUpdateTimesheetsInProgressStatus(true)
    this.props.handleAppState(
      {
        selectedDate: displayingDate,
      },
      async () => {
        this.props.clearCurrentEditableEntry()
        await this.props.updateViewData()
        this.handleUpdateTimesheetsInProgressStatus(false)
        this.focusProjectSelector()
        cb()
      },
    )
  }

  handleFavoriteProjectsSelection = async project => {
    try {
      const projectId = project._id

      const favoriteProjectIds = _.get(this.props, 'userSettings.favoriteProjectIds', [])

      const doesFavoriteProjectIdExist = _.includes(favoriteProjectIds, projectId)
      const newFavoriteProjectIds = doesFavoriteProjectIdExist
        ? _.filter(favoriteProjectIds, id => id !== projectId)
        : _.concat(favoriteProjectIds, projectId)

      const newUserSettings = {
        ...this.props.userSettings,
        favoriteProjectIds: newFavoriteProjectIds,
      }

      this.props.handleAppState({ userSettings: newUserSettings })

      await userSettingsService.setUserSettings(this.props.client, newUserSettings)
    } catch (e) {
      logError(e, 'handleFavoriteProjectsSelection: ')
    }
  }

  setDefaultProject = (userSettings, projectId) => {
    const initialCurrentEditableEntry = this.buildCurrentEditableEntry(
      { ...this.props.initialCurrentEditableEntry, originalProjectId: projectId },
      projectId,
    )
    this.props.handleAppState({
      userSettings,
      initialCurrentEditableEntry,
    })
  }

  handleSetDefaultProject = async projectId => {
    try {
      const newUserSettings = {
        ...this.props.userSettings,
        defaultProjectId: projectId,
      }
      this.setDefaultProject(newUserSettings, projectId)
      await userSettingsService.setUserSettings(this.props.client, newUserSettings)
    } catch (e) {
      logError(e, 'handleSetDefaultProject: ')
    }
  }

  handleClearDefaultProject = async () => {
    try {
      const newUserSettings = {
        favoriteProjectIds: this.props.userSettings.favoriteProjectIds,
        defaultProjectId: null,
      }
      this.setDefaultProject(newUserSettings, null)
      await userSettingsService.setUserSettings(this.props.client, newUserSettings)
    } catch (e) {
      logError(e, 'handleFavoriteProjectsSelection: ')
    }
  }

  addToQueueToSendLater = async (action, data) => {
    try {
      await EntryAction.add({
        action,
        data,
        sourceId: data.sourceId,
      })
      await this.props.updateViewData()
      this.props.clearCurrentEditableEntry()
      log('connection lost, entry added to the queue for retry later')
    } catch (e) {
      logError(e, 'App > addToQueueToSendLater > e: ')
    }
  }

  handleTimeEntryCreate = async entry => {
    try {
      const newTimeEntry = await timeEntryService.createTimeEntry({
        client: this.props.client,
        timeEntry: entry,
        selectedDelegateId: this.props.selectedDelegateId,
      })
      const timeEntry = addEventDataToEntry(newTimeEntry)
      const resultTimeEntry = eventUtils.parseLocalEntryDatesAndSource(timeEntry)
      this.props.clearCurrentEditableEntry()
      this.props.handleAppState(prevState => ({
        timeEntries: [...prevState.timeEntries, resultTimeEntry],
      }))
      this.props.setInitialCurrentEditableEntry()
      await TimeEntry.add(timeEntry)
      return resultTimeEntry
    } catch (e) {
      logError(e, 'app2js > handleTimeEntryCreate > e: ')
      throw e
    }
  }

  handleCreateEntry = async entryData => {
    const entry = await transformDataToValidEntry({
      client: this.props.client,
      eventData: entryData,
      selectedDelegateId: this.props.selectedDelegateId,
    })
    const timeEntry = await this.handleTimeEntryCreate(entry)
    return timeEntry
  }

  handleSafeCreateEntry = async entry => {
    const originalEntryDate =
      entry.originalEntryDate ||
      moment(entry.start)
        .startOf('day')
        .toLocalStringAsUTC()
    const entryWithEntryDate = {
      ...entry,
      originalEntryDate,
    }
    const entryWithLocalDates = {
      ...entryWithEntryDate,
      start: convertDateToLocalTimezone(entry.start),
      end: convertDateToLocalTimezone(entry.end),
    }
    if (!this.props.isConnected) {
      this.addToQueueToSendLater(CREATE, entryWithLocalDates)
      return
    }
    const onError = async e => {
      logError(e, 'handleSafeCreateEntry > e: ')
      this.props.clearCurrentEditableEntry()
      await updateUnhandledEntries({ handleAppState: this.props.handleAppState })
    }
    const onConnectionError = () => this.addToQueueToSendLater(CREATE, entryWithLocalDates)
    const create = () => this.handleCreateEntry(entryWithEntryDate)

    return handleSafeExecEntryAction(create, onError, onConnectionError)
  }

  handleUpdateEntry = async updatedEntry => {
    const approveState = updatedEntry.approveState || {}
    const updatedTimeEntry = await timeEntryService.updateTimeEntry({
      client: this.props.client,
      timeEntry: {
        ...updatedEntry,
        approveState: {
          status: approveState.status,
          rejectionReason: approveState.rejectionReason,
          modifiedBy: approveState.modifiedBy,
        },
      },
      selectedDelegateId: this.props.selectedDelegateId,
    })
    this.props.clearCurrentEditableEntry()
    this.props.handleAppState(prevState => ({
      timeEntries: prevState.timeEntries.map(timeEntry => {
        if (timeEntry._id !== updatedTimeEntry._id) return timeEntry
        return eventUtils.parseLocalEntryDatesAndSource(updatedTimeEntry)
      }),
    }))
  }

  handleSafeUpdateEntry = async entry => {
    const isOperation = isAnOperation(entry)
    if (isOperation) {
      await updateLocalEntry(entry)
      this.props.clearCurrentEditableEntry()
      return this.props.updateView()
    }

    if (!this.props.isConnected) {
      this.addToQueueToSendLater(UPDATE, entry, {})
      return
    }
    const onError = async e => {
      logError(e, 'handleEntryUpdate: ')
      this.props.clearCurrentEditableEntry()
    }
    const onConnectionError = () => this.addToQueueToSendLater(UPDATE, entry)
    const updateEntry = () => this.handleUpdateEntry(entry)

    await handleSafeExecEntryAction(updateEntry, onError, onConnectionError)
  }

  handleDeleteEntry = async entry => {
    await timeEntryService.deleteTimeEntry({
      client: this.props.client,
      timeEntry: entry,
      selectedDelegateId: this.props.selectedDelegateId,
    })
    log('handleTimeEntryDelete complete.')
    await this.props.updateViewData()
  }

  handleSafeDeleteEntry = async entry => {
    const isOperation = isAnOperation(entry)

    if (isOperation) {
      const isFunnelEntry = !_.isNil(entry.data._id)
      if (isFunnelEntry) await convertToDeleteAction(entry)
      else await removeEntryFromQueue(entry)
      this.props.clearCurrentEditableEntry()
      return this.props.updateView()
    }

    if (!this.props.isConnected) return this.addToQueueToSendLater(DELETE, entry)
    const onConnectionError = () => this.addToQueueToSendLater(DELETE, entry)
    const onError = async e => {
      logError(e, 'handleSafeDeleteEntry > e:')
    }
    const erase = async () => this.handleDeleteEntry(entry)
    return handleSafeExecEntryAction(erase, onError, onConnectionError)
  }

  getProjectWithPTOCodes = () => {
    const projects = this.props.projects.filter(f => {
      return !['KS Booked Time Off', 'Not assigned'].includes(f.name)
    })
    const unassignedProject = getProject({
      projects,
      projectId: UNASSIGNED_PROJECT_ID,
      projectRepliconId: UNASSIGNED_PROJECT_REPLICON_ID,
    })

    if (unassignedProject) return projects
    return [...projects, UNASSIGNED_PROJECT]
  }

  buildCurrentEditableEntry = (entryOrOperation, defaultProjectId) => {
    const projects = this.getProjectWithPTOCodes()

    const isOperation = isAnOperation(entryOrOperation)
    const currentEditableEntry = buildCurrentEditableEntry({
      entry: isOperation ? getEntry(entryOrOperation) : entryOrOperation,
      projects,
      defaultProjectId,
    })
    if (!isOperation) return currentEditableEntry
    return {
      ...entryOrOperation,
      data: currentEditableEntry,
    }
  }

  _buildCurrentEditableEntry = entryOrOperation => {
    return this.buildCurrentEditableEntry(
      entryOrOperation,
      this.props.userSettings.defaultProjectId,
    )
  }

  setCurrentEditableEntry = entryOrOperation => {
    const currentEditableEntry = this._buildCurrentEditableEntry(entryOrOperation)
    this.props.handleAppState({ currentEditableEntry })
  }

  addCalendarPlaceholderEvent = (selectedSlot, title = '') => {
    const entry = buildPlaceholderEntry(_.merge({}, selectedSlot, { title }))
    const newEntry = this._buildCurrentEditableEntry(entry)
    this.props.handleAppState({
      currentEditableEntry: newEntry,
    })
  }

  _handleUpdateCurrentEditableEntry = updates => {
    const { currentEditableEntry: prevcurrentEditableEntry } = this.props
    const isOperation = isAnOperation(prevcurrentEditableEntry)
    const currentEditableEntry = isOperation
      ? {
          ...prevcurrentEditableEntry,
          data: {
            ...prevcurrentEditableEntry.data,
            ...updates,
          },
        }
      : {
          ...prevcurrentEditableEntry,
          ...updates,
        }

    this.props.handleAppState({
      currentEditableEntry,
    })
  }

  _handleSetCurrentEditableEntry = (currentEditableEntry, cb) => {
    this.props.handleAppState(
      {
        currentEditableEntry,
      },
      cb,
    )
  }

  findEntryById = entryOrOperationToFind => {
    const isEntryToFindAnOperation = isAnOperation(entryOrOperationToFind)
    const idToFind = isEntryToFindAnOperation
      ? getSourceId(entryOrOperationToFind)
      : entryOrOperationToFind._id

    const { timeEntries, timeOffEntries, localEntries } = this.props
    const entries = _.concat(timeEntries, timeOffEntries, localEntries)
    return _.find(entries, entryOrOperation => {
      const isOperation = isAnOperation(entryOrOperation)
      if (!isOperation) return entryOrOperation._id === idToFind
      const sourceId = getSourceId(entryOrOperation)
      return sourceId === idToFind
    })
  }

  timesheetSubmit = async timesheet => {
    this.props.handleAppState({ isSubmitInProgress: true })
    try {
      const submittedTimesheet = await submitTimesheet({
        client: this.props.client,
        timesheet,
        selectedDelegateId: this.props.selectedDelegateId,
      })
      const updatedTimesheets = this.props.timesheets.map(t => {
        if (t._id === submittedTimesheet._id) return submittedTimesheet
        return t
      })
      this.props.handleAppState({ timesheets: updatedTimesheets })
    } catch (e) {
      logError(e, 'timesheetSubmit > ')
    }

    this.props.handleAppState({ isSubmitInProgress: false })
  }

  timesheetReopen = async timesheet => {
    this.props.handleAppState({ isSubmitInProgress: true })
    try {
      const reopenedTimesheet = await reopenTimesheet({
        client: this.props.client,
        timesheet,
        selectedDelegateId: this.props.selectedDelegateId,
      })
      const updatedTimesheets = this.props.timesheets.map(t => {
        if (t._id === reopenedTimesheet._id) return reopenedTimesheet
        return t
      })
      this.props.handleAppState({ timesheets: updatedTimesheets })
    } catch (e) {
      logError(e, 'timesheetReopen > ')
    }

    this.props.handleAppState({ isSubmitInProgress: false })
  }

  setTimesheetPending = async timesheet => {
    try {
      const updatedTimesheet = updateTimesheetApproveState({
        client: this.props.client,
        variables: {
          repliconId: timesheet.repliconId,
          status: 'pending',
        },
      })
      const updatedTimesheets = this.props.timesheets.map(t => {
        if (t._id === updatedTimesheet._id) return updatedTimesheet
        return t
      })
      this.props.handleAppState({ timesheets: updatedTimesheets })
    } catch (e) {
      logError(e, 'setTimesheetPending > ')
    }
  }

  // UNIFY
  dayTimesheetSubmission = async timesheet => {
    await this.setTimesheetPending(timesheet)
    if (timesheet.approvalStatus === OPEN) return this.timesheetSubmit(timesheet)
    if (timesheet.approvalStatus === APPROVED) return this.timesheetReopen(timesheet)
  }

  // UNIFY
  weekTimesheetsSubmission = async (timesheet, areAllTimesheetsSubmitted) => {
    if (areAllTimesheetsSubmitted) return this.timesheetReopen(timesheet)
    if (timesheet.approvalStatus === APPROVED) return
    return this.timesheetSubmit(timesheet)
  }

  // UNIFY
  _handleTimesheetSubmission = async () => {
    if (this.isDayViewPeriodSelected())
      return this.dayTimesheetSubmission(this.getSelectedDayTimesheet())

    const selectedWeekDateObjects = timeUtils.getSelectedDayWeekDays(this.props.selectedDate)
    const firstDay = timeUtils.substractDays(_.head(selectedWeekDateObjects), 1).toISOString()
    const timesheets = await Timesheet.where('startDate')
      .between(firstDay, _.last(selectedWeekDateObjects).toISOString(), true, true)
      .toArray()
    const areAllTimesheetsSubmitted = this.areAllTimesheetsSubmitted()

    for (const timesheet of timesheets) {
      await this.weekTimesheetsSubmission(timesheet, areAllTimesheetsSubmitted)
    }
  }

  setViewPeriodDay = () =>
    this.props.handleAppState({ viewPeriod: VIEW_PERIOD_DAY }, () => {
      this.props.updateViewData()
      this.props.clearCurrentEditableEntry()
    })

  setViewPeriodWeek = () =>
    this.props.handleAppState({ viewPeriod: VIEW_PERIOD_WEEK }, () => {
      this.props.updateViewData()
      this.props.clearCurrentEditableEntry()
    })

  // MOVE THIS TO SUBMISSION TOOLBAR
  areAllTimesheetsWithEntries = () => {
    const filteredTimesheets = _.filter(this.getWeekTimesheets(), timesheet => {
      const dayName = moment(timesheet.startDate).format('dddd')
      return dayName !== 'Sunday' && dayName !== 'Saturday'
    })
    return _.every(filteredTimesheets, timesheet => {
      const dayEntries = eventUtils.getSelectedDayEntries(timesheet, this.props.timeEntries)
      const dayTimeOffEntries = eventUtils.getSelectedDayEntriesByDate(
        timesheet,
        this.props.timeOffEntries,
      )
      return dayEntries.length > 0 || dayTimeOffEntries.length > 0
    })
  }

  areAllTimesheetsWithoutEntries = () => {
    const filteredTimesheets = _.filter(this.getWeekTimesheets(), timesheet => {
      const dayName = moment(timesheet.startDate).format('dddd')
      return dayName !== 'Sunday' && dayName !== 'Saturday'
    })
    return _.every(filteredTimesheets, timesheet => {
      const dayEntries = eventUtils.getSelectedDayEntries(timesheet, this.props.timeEntries)
      const dayTimeOffEntries = eventUtils.getSelectedDayEntriesByDate(
        timesheet,
        this.props.timeOffEntries,
      )
      return dayEntries.length === 0 && dayTimeOffEntries.length === 0
    })
  }

  handleSwitchIssueReportModalFromMenu = () => {
    this.props.handleAppState(prevState => ({
      isIssueReportModalFromMenuOpen: !prevState.isIssueReportModalFromMenuOpen,
    }))
  }

  handleSwitchDelegateAccessModalFromMenu = () => {
    this.props.handleAppState(prevState => ({
      isDelegateAccessModalFromMenuOpen: !prevState.isDelegateAccessModalFromMenuOpen,
    }))
  }

  getHandler = action => {
    const handlers = {
      [CREATE]: this.handleSafeCreateEntry,
      [UPDATE]: this.handleSafeUpdateEntry,
      [DELETE]: this.handleSafeDeleteEntry,
    }

    return action ? handlers[action] : () => {}
  }

  processLocalEntryOperation = async operation => {
    try {
      const action = getAction(operation)
      const handler = this.getHandler(action)

      const entry = getEntry(operation)
      await handler(entry)

      return getSourceId(operation)
    } catch (e) {
      logError(e, 'app2js > processLocalOperation > e:')
    }
  }

  saveLocalEntryOperations = async () => {
    try {
      const uiPeriod = this.props.getUIRefreshTimePeriod()
      this.props.handleAppState({ isSubmitInProgress: true })

      const entryActions = this.props.localEntries.filter(operation => {
        return doesOperationFallInsideDateRange(operation, uiPeriod)
      })
      if (entryActions.length === 0) return

      const ids = await asyncMapLimit(entryActions, 2, (operation, callback) =>
        this.processLocalEntryOperation(operation).then(id => callback(null, id)),
      )
      await EntryAction.bulkDelete(ids)
      log(`\tSuccessfully processed ${ids.length} local entry operations`)
      this.props.handleAppState({ isSubmitInProgress: false })
      await this.props.updateViewData()

      // deletes in disk offline entries
      if (ipcRenderer) await ipcRenderer.send('delete-offline-entries')
    } catch (e) {
      this.props.handleAppState({ isSubmitInProgress: false })
      logError(e, 'app/index.js > saveLocalEntryOperations > e: ')
    }
  }

  openNoFreeGapModal = () => this.props.handleAppState({ isNoFreeGapModalOpen: true })

  handleSetReminderSettings = newSettings => {
    this.props.handleAppState({
      reminderSettings: newSettings,
    })
  }

  selectedDelegateIdHandler = async selectedDelegateId => {
    this.props.handleSelectedDelegateId(selectedDelegateId, true, initialState)

    await Email.clear()
    await Event.clear()
    await Timesheet.clear()
    await TimeEntry.clear()
    await TimeOffEntry.clear()
    await EntryAction.clear()
    await UnhandledEntry.clear()
    await Assignment.clear()

    history.push('/welcome')
    history.replace('/welcome')
  }

  renderCalendar = () => {
    return (
      <Calendar
        view={this.props.view}
        handleChangeView={newView => this.props.handleAppState({ view: newView })}
        uiRefreshTimePeriod={this.props.getUIRefreshTimePeriod()}
        events={this.props.events}
        timeEntries={this.props.timeEntries}
        timeOffEntries={this.props.timeOffEntries}
        localEntries={this.props.localEntries}
        unhandledEntries={this.props.unhandledEntries}
        exchangeEmails={this.props.exchangeEmails}
        exchangeEvents={this.props.exchangeEvents}
        usedExchangeEvents={this.getUsedExchangeEvents()}
        clearCurrentEditableEntryProject={this.clearCurrentEditableEntryProject}
        handleCreateEntriesForAllEvents={this.openAddAllEntriesConfirmationModal}
        hasTimesheetUnassignedEntries={this.hasTimesheetUnassignedEntries()}
        hasWeekTimesheetsUnassignedEntries={this.hasWeekTimesheetsUnassignedEntries()}
        areAllTimesheetsSubmitted={this.areAllTimesheetsSubmitted()}
        person={this.props.userInfo}
        showModal={this.handleModalOpen}
        handleUpload={this.handleUpload}
        handleReloadApp={this.handleReloadApp}
        isSubmitInProgress={this.props.isSubmitInProgress}
        mostRecentlyUsedProjectIds={this.props.mostRecentlyUsedProjectIds}
        isTimesheetLoading={this.props.isTimesheetLoading}
        timeOffCodes={this.props.timeOffCodes}
        selectedTimesheet={this.getSelectedDayTimesheet()}
        selectedDate={this.props.selectedDate}
        isTimesheetLocked={this.isTimesheetLocked()}
        getTimeEntriesEvents={this.getTimeEntriesEvents}
        timesheets={this.props.timesheets}
        clearCurrentEditableEntry={this.props.clearCurrentEditableEntry}
        projects={this.props.projects}
        userSettings={this.props.userSettings}
        handleCalendarSelect={this.handleCalendarSelect}
        handleFavoriteProjectsSelection={this.handleFavoriteProjectsSelection}
        handleSetDefaultProject={this.handleSetDefaultProject}
        handleClearDefaultProject={this.handleClearDefaultProject}
        handleSafeCreateEntry={this.handleSafeCreateEntry}
        handleSafeUpdateEntry={this.handleSafeUpdateEntry}
        handleEntryDelete={this.handleSafeDeleteEntry}
        addCalendarPlaceholderEvent={this.addCalendarPlaceholderEvent}
        currentEditableEntry={this.props.currentEditableEntry}
        handleUpdateCurrentEditableEntry={this._handleUpdateCurrentEditableEntry}
        handleSetCurrentEditableEntry={this._handleSetCurrentEditableEntry}
        getProjectWithPTOCodes={this.getProjectWithPTOCodes}
        findEntryById={this.findEntryById}
        handleTimesheetSubmission={this._handleTimesheetSubmission}
        initialSync={this.props.initialSync}
        handleInitialSyncRetry={this.props.handleInitialSyncRetry}
        isDayViewPeriodSelected={this.isDayViewPeriodSelected()}
        setViewPeriodDay={this.setViewPeriodDay}
        setViewPeriodWeek={this.setViewPeriodWeek}
        areAllTimesheetsWithEntries={this.areAllTimesheetsWithEntries()}
        areAllTimesheetsWithoutEntries={this.areAllTimesheetsWithoutEntries()}
        handleSwitchIsIssueReportModalOpen={this.handleSwitchIssueReportModalFromMenu}
        handleSwitchIsDelegateAccessModalOpen={this.handleSwitchDelegateAccessModalFromMenu}
        buildCurrentEditableEntry={this._buildCurrentEditableEntry}
        saveLocalEntryOperations={this.saveLocalEntryOperations}
        isFetchingTimesheetData={this.props.isFetchingTimesheetData}
        isConnected={this.props.isConnected}
        handleNoFreeGapModalOpen={this.openNoFreeGapModal}
        reminderSettings={this.props.reminderSettings}
        handleSetReminderSettings={this.handleSetReminderSettings}
        delegateAccessList={this.props.delegateAccesses}
        handleSelectedDelegateId={this.selectedDelegateIdHandler}
        selectedDelegateId={this.props.selectedDelegateId}
        assignments={this.props.assignments}
        projectSelectorRef={this.projectSelectorRef}
        focusProjectSelector={this.focusProjectSelector}
        setCurrentEditableEntry={this.setCurrentEditableEntry}
        initialCurrentEditableEntry={this.props.initialCurrentEditableEntry}
        updateViewData={this.props.updateViewData}
        client={this.props.client}
      />
    )
  }

  closeAddAllEntriesFromEventsModal = () =>
    this.props.handleAppState({ isAddAllEntriesFromEventsModalOpen: false })

  handleCreateEntryFromEvent = project => async event => {
    const { projects } = this.props
    const projectId = event.projectId
    const eventProject = projectId ? getProject({ projects, projectId }) : null

    const projectOrEventProject = _.isNil(eventProject) ? project : eventProject

    const fieldsToInherit = projectUtils.getFieldsToInheritToEntry(projectOrEventProject)
    const billable =
      projectOrEventProject.billable === 'Both' ? 'ProjectRate' : projectOrEventProject.billable

    const newEntry = {
      ...fieldsToInherit,
      start: new Date(event.start),
      end: new Date(event.end),
      originalDurationInHours: getDurationInHours(event.start, event.end),
      resourceId: FUNNEL_ENTRIES_RESOURCE_ID,
      referenceId: event.id,
      referenceType: REFERENCE_TYPE.CALENDAR,
      creationWay: event.creationWay,
      originalComment: event.subject,
      billable,
      sourceId: eventUtils.getEventId(
        _.get(projectOrEventProject, '_id', 'X'),
        event.subject,
        event.start,
        event.end,
      ),
      eventTagId: event.tagId,
    }
    await this.handleSafeCreateEntry(newEntry)
  }

  // UNIFY
  createEntriesForAllEventsInADay = async (project, creationWay) => {
    const { timeEntries, exchangeEvents } = this.props
    const selectedTimesheet = this.getSelectedDayTimesheet()
    if (selectedTimesheet.approvalStatus === APPROVED) return
    const todayEntriesReferenceIds = _.map(
      eventUtils.getSelectedDayEntries(selectedTimesheet, timeEntries),
      entry => entry.referenceId,
    )

    const events = eventUtils.getEventsForDay(new Date(selectedTimesheet.startDate), exchangeEvents)

    const filteredEvents = _.chain(events)
      .filter(event => !_.includes(todayEntriesReferenceIds, event.id) && !event.isAllDay)
      .map(event => ({ ...event, creationWay }))
      .value()

    await Promise.all(filteredEvents.map(this.handleCreateEntryFromEvent(project)))
  }

  // UNIFY
  createEntriesForAllEventsInAWeek = async (project, creationWay) => {
    const weekEvents = _.map(this.getWeekTimesheets(), timesheet => {
      if (timesheet.approvalStatus === APPROVED) return []
      const dayEntries = eventUtils.getSelectedDayEntries(timesheet, this.props.timeEntries)
      const dayEntriesReferenceIds = _.map(dayEntries, entry => entry.referenceId)
      const events = eventUtils.getEventsForDay(
        new Date(timesheet.startDate),
        this.props.exchangeEvents,
      )
      return _.filter(
        events,
        event => !_.includes(dayEntriesReferenceIds, event.id) && !event.isAllDay,
      )
    })

    const events = _.chain(weekEvents)
      .flatten()
      .map(event => ({ ...event, creationWay }))
      .value()

    await Promise.all(events.map(this.handleCreateEntryFromEvent(project)))
  }

  // UNIFY
  handleCreateEntriesForAllEvents = async () => {
    const { projects, userSettings, isAllEventsButton, view } = this.props
    const { defaultProjectId } = userSettings

    const latestDefaultProjectId = defaultProjectId
    const lastDefaultProject = !_.isNil(latestDefaultProjectId)
      ? getProject({ projects, projectId: latestDefaultProjectId })
      : null

    const projectUsedToCreateAllEventsEntries = !_.isNil(lastDefaultProject)
      ? lastDefaultProject
      : getProject({
          projects,
          projectId: UNASSIGNED_PROJECT_ID,
        })

    const creationWay = isAllEventsButton
      ? CREATION_WAY.TIMELINE_ADD_ALL_BUTTON
      : CREATION_WAY[`${view.toUpperCase()}_ADD_ALL`]

    if (this.isDayViewPeriodSelected()) {
      await this.createEntriesForAllEventsInADay(projectUsedToCreateAllEventsEntries, creationWay)
      return
    }
    await this.createEntriesForAllEventsInAWeek(projectUsedToCreateAllEventsEntries, creationWay)
  }

  handleOkAddAllEntriesFromEventsModal = async () => {
    this.props.handleAppState({ areEventsBeingAdded: true })
    await this.handleCreateEntriesForAllEvents()
    this.closeAddAllEntriesFromEventsModal()
    this.props.handleAppState({ areEventsBeingAdded: false })
  }

  renderAddAllEntriesFromEventsModal = () => {
    return (
      <ConfirmationModal
        isOpen={this.props.isAddAllEntriesFromEventsModalOpen}
        title="Are you sure you want to add all events to your timesheet?"
        actionButtons={[
          {
            handler: this.closeAddAllEntriesFromEventsModal,
            color: 'secondary',
            label: 'Cancel',
            isDisabled: this.props.areEventsBeingAdded,
          },
          {
            handler: this.handleOkAddAllEntriesFromEventsModal,
            color: 'primary',
            label: 'Ok',
            isDisabled: this.props.areEventsBeingAdded,
          },
        ]}
      />
    )
  }

  clearCsvSuccessState = () =>
    this.props.handleAppState({
      successCsvInfoTotal: {},
    })

  clearCsvFailState = () =>
    this.props.handleAppState({
      failedCsvInfoTotal: {},
    })

  handleDismissUnhandledEntries = async dateArr => {
    try {
      await Promise.all(dateArr.map(deleteUnhandledEntriesForDate))
      await updateUnhandledEntries({ handleAppState: this.props.handleAppState })
    } catch (e) {
      logError(e, 'Appjs > handleDismissUnhandledEntries > e: ')
    }
  }

  handleAddUnhandledEntries = async dateStr => {
    const selectedDate = parseUTCStartDate(dateStr)
    try {
      const timesheet = await Timesheet.get({ startDate: selectedDate })
      await this.timesheetReopen(timesheet)

      const entries = await getUnhandledEntriesForDate(dateStr)
      for (const entry of entries) {
        await this.handleSafeCreateEntry(entry)
      }

      await deleteUnhandledEntriesForDate(dateStr)
      await updateUnhandledEntries({ handleAppState: this.props.handleAppState })
    } catch (e) {
      logError(e, 'app/index.js > handleAddUnhandledEntries > e:')
    }
  }

  handleAddUnhandledEntriesBulk = async dateArr => {
    this.props.handleAppState({ csvActionButtonsBlocked: true })
    await Promise.all(dateArr.map(this.handleAddUnhandledEntries))
    this.props.handleAppState({ csvActionButtonsBlocked: false })
  }

  handleReplaceUnhandledEntries = async dateStr => {
    const selectedDate = parseUTCStartDate(dateStr)
    try {
      const timesheet = await Timesheet.get({ startDate: selectedDate })
      await this.timesheetReopen(timesheet)
      const { startISO, endISO } = getUTCStartEndISOForTheDay(dateStr)

      const localEntries = await TimeEntry.where('originalEntryDate')
        .between(
          convertDateToLocalTimezone(startISO),
          convertDateToLocalTimezone(endISO),
          true,
          true,
        )
        .toArray()
      const localPTOEntries = await TimeOffEntry.where('entryDate')
        .between(
          convertDateToLocalTimezone(startISO),
          convertDateToLocalTimezone(endISO),
          true,
          true,
        )
        .toArray()

      for (const entry of localEntries) {
        await this.handleSafeDeleteEntry(entry)
      }
      for (const ptoEntry of localPTOEntries) {
        await this.handleSafeDeleteEntry(ptoEntry)
      }
      const entries = await getUnhandledEntriesForDate(dateStr)
      for (const entry of entries) {
        await this.handleSafeCreateEntry(entry)
      }
      await deleteUnhandledEntriesForDate(dateStr)
      await updateUnhandledEntries({ handleAppState: this.props.handleAppState })
    } catch (e) {
      logError(e, 'app/index.js > handleReplaceUnhandledEntries > e: ')
    }
  }

  handleReplaceUnhandledEntriesBulk = async dateArr => {
    this.props.handleAppState({ csvActionButtonsBlocked: true })
    await Promise.all(dateArr.map(this.handleReplaceUnhandledEntries))
    this.props.handleAppState({ csvActionButtonsBlocked: false })
  }

  renderCsvMultiModal = () => {
    return (
      <CsvMultiModal
        successCsvInfoTotal={this.props.successCsvInfoTotal}
        failedCsvInfoTotal={this.props.failedCsvInfoTotal}
        clearCsvSuccessState={this.clearCsvSuccessState}
        clearCsvFailState={this.clearCsvFailState}
        unhandledEntriesInfo={this.props.unhandledEntriesInfo}
        onDismiss={this.handleDismissUnhandledEntries}
        onAdd={this.handleAddUnhandledEntriesBulk}
        onReplace={this.handleReplaceUnhandledEntriesBulk}
        csvActionButtonsBlocked={this.props.csvActionButtonsBlocked}
        csvUploadData={this.props.csvUploadData}
      />
    )
  }

  renderIssueReportFromMenu = () => {
    return (
      <IssueReportFromMenu
        client={this.props.client}
        isOpen={this.props.isIssueReportModalFromMenuOpen}
        handleSwitchIsIssueReportModalOpen={this.handleSwitchIssueReportModalFromMenu}
        viewPeriod={this.props.viewPeriod}
        selectedDate={this.props.selectedDate}
      />
    )
  }

  closeIssueReportModalFromSentry = () => {
    this.props.handleAppState({
      isIssueReportModalFromSentryOpen: false,
      sentryError: {},
    })
  }

  renderIssueReportFromSentry = () => {
    return (
      <IssueReportFromSentry
        client={this.props.client}
        error={this.props.sentryError}
        isOpen={this.props.isIssueReportModalFromSentryOpen}
        closeIssueReportModalFromSentry={this.closeIssueReportModalFromSentry}
        viewPeriod={this.props.viewPeriod}
        selectedDate={this.props.selectedDate}
      />
    )
  }

  renderDelegateAccessFromMenu = () => {
    return (
      <DelegateAccessFromMenu
        isOpen={this.props.isDelegateAccessModalFromMenuOpen}
        handleSwitchIsDelegateAccessModalOpen={this.handleSwitchDelegateAccessModalFromMenu}
      />
    )
  }

  renderAssignments = () => {
    return (
      <Assignments
        person={this.props.userInfo}
        projects={this.props.projects}
        isConnected={this.props.isConnected}
      />
    )
  }

  render() {
    const { hasUserGotStarted, initialSync, userInfo } = this.props
    // if user's position or user's employeeType are Contractor - Expert, do not show assignments, reports and graph sections
    const isExpert =
      _.get(userInfo, 'position') === 'Contractor - Expert' ||
      _.get(userInfo, 'employeeType', '').includes('Contractor - Expert')

    return (
      <div data-testid="app" className={`app-window ${isWindows() ? 'windows' : ''}`}>
        {!this.props.isConnected && this.props.isUserLoggedIn && <OfflineModeMessage />}
        {this.renderModalDialog()}
        {this.renderNoFreeGapModal()}
        <div
          className="flex-column"
          style={this.props.isConnected ? { height: '100vh' } : { height: `calc(100vh - 51px)` }}
        >
          <Switch>
            <Route
              path="/auth"
              render={() => (
                <Auth
                  isInitialSyncInProgress={initialSync.inProgress}
                  hasUserGotStarted={hasUserGotStarted}
                />
              )}
            />
            <PrivateRoute path="/welcome">{this.renderWelcomeScreen()}</PrivateRoute>
            <PrivateRoute path="/error">{this.renderErrorScreen()}</PrivateRoute>
            <PrivateRoute exact path="/">
              {this.renderCalendar()}
            </PrivateRoute>

            <PrivateRoute path="/lead-view">
              <LeadView person={this.props.userInfo} client={this.props.client} />
            </PrivateRoute>

            {!isExpert && (
              <PrivateRoute path="/reports">
                <ReportsPage />
              </PrivateRoute>
            )}
            {IS_GRAPH_ENABLED && !isExpert && (
              <PrivateRoute path="/graph">
                <Graph />
              </PrivateRoute>
            )}
            {IS_ASSIGNMENTS_ENABLED && !isExpert && (
              <PrivateRoute path="/assignments">{this.renderAssignments()}</PrivateRoute>
            )}
          </Switch>
          {this.renderAddAllEntriesFromEventsModal()}
        </div>
        {this.renderCsvMultiModal()}
        {this.renderIssueReportFromMenu()}
        {this.renderIssueReportFromSentry()}
        {IS_DELEGATE_ENABLED && this.renderDelegateAccessFromMenu()}
      </div>
    )
  }
}

Application.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  isConnected: PropTypes.bool.isRequired,
  client: PropTypes.object.isRequired,
  handleAppState: PropTypes.func.isRequired,
  handleInitialSyncRetry: PropTypes.func.isRequired,
  startInitialSync: PropTypes.func.isRequired,
  getUIRefreshTimePeriod: PropTypes.func.isRequired,
  updateViewData: PropTypes.func.isRequired,
  updateView: PropTypes.func.isRequired,
  modal: PropTypes.object,
  isNoFreeGapModalOpen: PropTypes.bool,
  numberOfInitialSyncRetries: PropTypes.number,
  initialSync: PropTypes.object,
  events: PropTypes.array,
  timeEntries: PropTypes.array,
  localEntries: PropTypes.array,
  selectedDate: PropTypes.any,
  timesheets: PropTypes.array,
  csvUploadData: PropTypes.object,
  viewPeriod: PropTypes.string,
  unhandledEntries: PropTypes.array,
  userSettings: PropTypes.object,
  selectedDelegateId: PropTypes.string,
  projects: PropTypes.array,
  currentEditableEntry: PropTypes.object,
  timeOffEntries: PropTypes.array,
  delegateAccesses: PropTypes.array,
  view: PropTypes.string,
  exchangeEmails: PropTypes.array,
  exchangeEvents: PropTypes.array,
  isSubmitInProgress: PropTypes.bool,
  mostRecentlyUsedProjectIds: PropTypes.array,
  isTimesheetLoading: PropTypes.bool,
  timeOffCodes: PropTypes.array,
  isFetchingTimesheetData: PropTypes.bool,
  reminderSettings: PropTypes.object,
  isAllEventsButton: PropTypes.bool,
  isAddAllEntriesFromEventsModalOpen: PropTypes.bool,
  areEventsBeingAdded: PropTypes.bool,
  successCsvInfoTotal: PropTypes.object,
  failedCsvInfoTotal: PropTypes.object,
  unhandledEntriesInfo: PropTypes.object,
  csvActionButtonsBlocked: PropTypes.bool,
  isIssueReportModalFromMenuOpen: PropTypes.bool,
  isDelegateAccessModalFromMenuOpen: PropTypes.bool,
  hasUserGotStarted: PropTypes.bool,
  isIssueReportModalFromSentryOpen: PropTypes.bool,
  sentryError: PropTypes.object,
  assignments: PropTypes.array,
  clearCurrentEditableEntry: PropTypes.func,
  setInitialCurrentEditableEntry: PropTypes.func,
  initialCurrentEditableEntry: PropTypes.object,
  handleSelectedDelegateId: PropTypes.func,
}

export default Application

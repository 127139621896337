import React, { useCallback, useEffect, useMemo, useState } from 'react'
import TableCell from '@material-ui/core/TableCell'

import PropTypes from 'prop-types'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import { CancelOutlined } from '@material-ui/icons'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import {
  ApprovalsPeopleContainer,
  ApprovalViewContainer,
  BudgetContainer,
  DetailsActionContainer,
  DetailsViewContainer,
  DetailsViewHeaderContainer,
  StyledApproveButton,
  StyledDetailsSpan,
  StyledMutedButton,
  StyledWeekContainer,
  TableWrapper,
  WeekContainer,
} from './ApprovalView.styles'
import { ProjectsTableContainer, StyledTableHead } from './ProjectsView.styles'
import EntryColumns from './Components/ApprovalsTableColumn'
import { updateTimeEntry } from '../../entities/timeEntry/service'
import { WeekTabButton } from '../Calendar/Header/styles'
import ActionButtons from './Components/ActionButtons'
import Calendar from './Components/Calendar'
import { calculateBudgetAndPacingInWeek, groupTimeEntries } from './Approvals.utils'
import ProjectSelection from '../../components/ProjectSelection'
import { LoadingIndicator } from '../Calendar/style'
import formatCurrency from '../../utils/formatCurrency'
import moment from 'moment'

const ApprovalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

const ApprovalsInformativeAdvice = () => (
  <p style={{ width: '80%', maxWidth: '1000px' }}>
    Once a timesheet is approved, the approved timesheets will flow into &nbsp;
    <a href="https://stacks.keystonestrategy.io/" target="_blank" rel="noreferrer">
      Stacks
    </a>
    &nbsp; You must manage your approvals to ensure your project is up to date. Glimpse should be a
    true reflection of actual hours worked by employees. Any write-offs must be done in Stacks.
    Please do not reject time entries in Glimpse for the purposes of write offs
  </p>
)

document.body.style.overflow = 'auto'

const ProjectApprovals = ({
  projects,
  client,
  onEntryUpdate,
  onUpdateManyEntries,
  timeEntries: rawTimeEntries,
  timesheets,
  people,
  person,
  expenses,
  availableWeeks: allWeeks = [],
  onWritteOff,
  onReject,
  rates,
  calendarValues,
  calendarResume,
  onSplitEntry,
  onProjectSelect,
}) => {
  const [selectedProject, setSelectedProject] = useState(projects[0])
  const [personDetailsId, setPersonDetailsId] = useState()
  const [showDetails, setShowDetails] = useState(false)
  const timeEntries = useMemo(() => {
    return groupTimeEntries(rawTimeEntries, timesheets.submitted, allWeeks)
  }, [timesheets, rawTimeEntries, allWeeks])

  const availableWeeks = useMemo(
    () =>
      allWeeks.filter(week => {
        return Object.keys(timeEntries[selectedProject?._id] || {}).includes(week.stringWeek)
      }),
    [allWeeks, timeEntries, selectedProject],
  )

  const [selectedWeek, setSelectedWeek] = useState(availableWeeks[0])
  useEffect(() => {
    setSelectedWeek(availableWeeks[0])
  }, [availableWeeks])
  useEffect(() => {
    for (let i = 0; i < projects.length; i++) {
      const peopleEntries = timeEntries?.[selectedProject?._id]?.[selectedWeek?.stringWeek]
      if (peopleEntries) {
        setSelectedProject(projects[i])
        onProjectSelect(projects[i])
        break
      }
    }
  }, [projects])

  const setProject = id => {
    setSelectedProject(projects.find(project => project._id === id))
  }

  const updateTimeEntryLocal = useCallback(
    async (entry, status = 'approved', reason = '') => {
      const updatedEntry = {
        ...entry,
        approveState: {
          status,
          modifiedBy: person._id,
          modifiedOn: new Date(),
          rejectionReason: reason,
        },
      }
      const result = await updateTimeEntry({ client, timeEntry: updatedEntry })
      return result
    },
    [client, onEntryUpdate],
  )

  const [selectedEntries, setSelectedEntries] = useState({})

  const onSelect = useCallback(
    entry => {
      if (
        selectedEntries[entry.personId] &&
        selectedEntries[entry.personId].some(currentEntry => currentEntry._id === entry._id)
      ) {
        setSelectedEntries({
          ...selectedEntries,
          [entry.personId]: selectedEntries[entry.personId].filter(
            personEntry => personEntry._id !== entry._id,
          ),
        })
        return
      }

      setSelectedEntries({
        ...selectedEntries,
        [entry.personId]: [...(selectedEntries[entry.personId] || []), entry],
      })
    },
    [selectedEntries, setSelectedEntries],
  )

  const approveAll = entriesToApprove => {
    const promises = entriesToApprove.map(async entry => {
      return updateTimeEntryLocal(entry)
    })

    Promise.all(promises).then(() => {
      onUpdateManyEntries(entriesToApprove)
    })
  }

  const handleApproveAll = id => {
    const filteredPersonEntries = peopleEntries[id]
    const approvedEntries = filteredPersonEntries.map(entry => {
      return {
        ...entry,
        approveState: {
          status: 'approved',
          modifiedBy: person._id,
          modifiedOn: new Date(),
        },
      }
    })

    approveAll(approvedEntries)
  }
  const peopleEntries =
    (timeEntries &&
      selectedProject &&
      selectedWeek &&
      timeEntries[selectedProject?._id]?.[selectedWeek?.stringWeek]) ||
    {}

  const projectExpenses = useMemo(
    () => expenses.filter(expense => expense.projectId === selectedProject._id),
    [selectedProject],
  )

  const selectedWeekExpenses = useMemo(
    () =>
      projectExpenses.filter(expense => {
        if (!selectedWeek) return false
        return moment.utc(expense.incurredDate).isoWeek() === selectedWeek.start.isoWeek()
      }),
    [projectExpenses, selectedWeek],
  )

  const { actualPacing, actualUsed, weekBudget } = useMemo(() => {
    if (!timeEntries || !selectedProject || !selectedWeek) return {}
    return calculateBudgetAndPacingInWeek(selectedWeek.start, selectedProject, peopleEntries, selectedWeekExpenses, rates)
  }, [timeEntries, selectedProject, selectedWeek])

  const handleShowDetails = id => {
    setPersonDetailsId(id)
    setShowDetails(true)
  }
  if (!selectedProject)
    return (
      <LoadingIndicator>
        <CircularProgress />
      </LoadingIndicator>
    )
  const renderPersonDetails = (
    <DetailsViewContainer>
      <DetailsViewHeaderContainer>
        <StyledDetailsSpan>{people[personDetailsId]?.fullName}</StyledDetailsSpan>
        <StyledApproveButton onClick={() => setShowDetails(false)}>Ok</StyledApproveButton>
      </DetailsViewHeaderContainer>
      <TableContainer component={ProjectsTableContainer} rows={6}>
        <Table aria-label="simple table">
          <StyledTableHead>
            <TableRow>
              <TableCell width={50}>Date</TableCell>
              <TableCell width={200}>Comment</TableCell>

              <TableCell width={50}>Hours</TableCell>
              <TableCell width={50}>Billable</TableCell>
              <TableCell width={50}>Rate</TableCell>
              <TableCell
                style={{
                  minWidth: '150px',
                }}
                width={80}
              >
                Total
              </TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {peopleEntries &&
              personDetailsId &&
              peopleEntries[personDetailsId] &&
              peopleEntries[personDetailsId].map(entry => {
                return (
                  <TableRow>
                    <EntryColumns
                      onUpdateEntry={onUpdateManyEntries}
                      entry={entry}
                      onSelect={onSelect}
                      person={person}
                      client={client}
                      rates={rates[selectedProject?._id]}
                      project={selectedProject}
                    />
                    <TableCell
                      style={{
                        minWidth: '150px',
                        display: 'flex',
                        justifyContent: 'space-around',
                      }}
                    >
                      {entry.status !== 'WRITEOFF' && (
                        <ActionButtons
                          updateTimeEntryLocal={updateTimeEntryLocal}
                          entry={entry}
                          onWritteOff={onWritteOff}
                          onReject={onReject}
                          selectedWeek={selectedWeek}
                          rates={rates[selectedProject?._id]}
                          client={client}
                          onSplitEntry={onSplitEntry}
                        />
                      )}
                      {entry.status === 'WRITEOFF' && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '2px',
                          }}
                        >
                          <IconButton onClick={() => onWritteOff(entry, true)}>
                            <CancelOutlined />
                          </IconButton>
                          <span> Write Off</span>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </DetailsViewContainer>
  )

  return (
    <ApprovalsContainer>
      <ApprovalViewContainer>
        <div>
          {selectedProject && (
            <ProjectSelection
              projects={projects}
              selectedProject={selectedProject?._id}
              setSelectedProject={project => {
                setProject(project)
                onProjectSelect(project)
              }}
            />
          )}
          <div style={{ marginBottom: 30 }} />
          {calendarValues.current?.[selectedProject?._id] && (
            <Calendar
              data={calendarValues.current[selectedProject._id]}
              resume={calendarResume.current[selectedProject._id]}
              project={selectedProject}
            />
          )}
        </div>
        <ApprovalsPeopleContainer>
          {weekBudget && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: 20,
                width: '100%',
                marginBottom: 20,
              }}
            >
              <BudgetContainer>
                <Typography>
                  <strong>Project Budget (Week)</strong>
                </Typography>
                <Typography>{formatCurrency(weekBudget, selectedProject.CurrencyIsoCode)}</Typography>
              </BudgetContainer>
              <BudgetContainer>
                <Typography>
                  <strong>Project Actual (Week)</strong>
                </Typography>
                <Typography>{formatCurrency(actualUsed, selectedProject.CurrencyIsoCode)}</Typography>
              </BudgetContainer>
              <BudgetContainer>
                <Typography>
                  <strong> Pacing</strong>{' '}
                </Typography>
                <Typography>{actualPacing}%</Typography>
              </BudgetContainer>
            </div>
          )}

          <StyledWeekContainer>
            {availableWeeks.length > 0 &&
              availableWeeks.map(week => {
                return (
                  <WeekTabButton
                    key={week.stringWeek}
                    minWidth="200px"
                    onClick={() => {
                      setSelectedWeek(week)
                    }}
                    selected={week.stringWeek === selectedWeek?.stringWeek}
                  >
                    {week.stringWeek}
                  </WeekTabButton>
                )
              })}
          </StyledWeekContainer>

          <WeekContainer>
            <TableWrapper>
              <TableContainer component={ProjectsTableContainer} rows={5}>
                <Table aria-label="simple table">
                  <StyledTableHead>
                    <TableRow>
                      <TableCell align="Left">Person</TableCell>
                      <TableCell align="center">Sun</TableCell>
                      <TableCell align="center">Mon</TableCell>
                      <TableCell align="center">Tues</TableCell>
                      <TableCell align="center">Wed</TableCell>
                      <TableCell align="center">Thur</TableCell>
                      <TableCell align="center">Fri</TableCell>
                      <TableCell align="center">Sat</TableCell>
                      <TableCell align="center">Totals</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </StyledTableHead>
                  {peopleEntries &&
                    Object.entries(peopleEntries).map(([id, entries]) => {
                      const hoursPerDay = entries.reduce(
                        (acc, entry) => {
                          acc[entry.weekDay] = (acc[entry.weekDay] || 0) + +entry.durationInHours
                          return acc
                        },
                        {
                          Sunday: 0,
                          Monday: 0,
                          Tuesday: 0,
                          Wednesday: 0,
                          Thursday: 0,
                          Friday: 0,
                          Saturday: 0,
                        },
                      )

                      return (
                        <TableBody>
                          <TableRow>
                            <TableCell align="left">{people[id].fullName}</TableCell>
                            <TableCell align="center">{hoursPerDay.Sunday.toFixed(2)}</TableCell>
                            <TableCell align="center">{hoursPerDay.Monday.toFixed(2)}</TableCell>
                            <TableCell align="center">{hoursPerDay.Tuesday.toFixed(2)}</TableCell>
                            <TableCell align="center">{hoursPerDay.Wednesday.toFixed(2)}</TableCell>
                            <TableCell align="center">{hoursPerDay.Thursday.toFixed(2)}</TableCell>
                            <TableCell align="center">{hoursPerDay.Friday.toFixed(2)}</TableCell>
                            <TableCell align="center">{hoursPerDay.Saturday.toFixed(2)}</TableCell>
                            <TableCell align="center">
                              {Object.values(hoursPerDay)
                                .reduce((acc, hours) => acc + hours, 0)
                                .toFixed(2)}
                            </TableCell>
                            <TableCell align="center">
                              <DetailsActionContainer>
                                <StyledMutedButton
                                  onClick={() => handleShowDetails(people[id]?._id)}
                                >
                                  Details
                                </StyledMutedButton>
                                <StyledApproveButton
                                  onClick={() => handleApproveAll(people[id]?._id)}
                                >
                                  Approve
                                </StyledApproveButton>
                              </DetailsActionContainer>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )
                    })}
                </Table>
              </TableContainer>
            </TableWrapper>
            {showDetails && renderPersonDetails}
          </WeekContainer>
          <ApprovalsInformativeAdvice />
        </ApprovalsPeopleContainer>
      </ApprovalViewContainer>
    </ApprovalsContainer>
  )
}

ProjectApprovals.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object),
  client: PropTypes.object.isRequired,
  onEntryUpdate: PropTypes.func.isRequired,
  onUpdateManyEntries: PropTypes.func.isRequired,
  timeEntries: PropTypes.object,
  availableWeeks: PropTypes.object,
  people: PropTypes.object,
  timesheets: PropTypes.object,
  person: PropTypes.object,
  onWritteOff: PropTypes.func,
  onReject: PropTypes.func,
  rates: PropTypes.object,
  calendarValues: PropTypes.object,
  calendarResume: PropTypes.object,
  onSplitEntry: PropTypes.func,
  onProjectSelect: PropTypes.func,
}

export default ProjectApprovals

import React, { useEffect, useState } from 'react'

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import PropTypes from 'prop-types'
import { useFavicon } from 'react-use'
import Input from '@material-ui/core/Input'
import { StyledTableHead } from '../ProjectsView.styles'
import {
  StyledApproveButton,
  StyledButtonContainer,
  StyledMutedButton,
  StyledRejectReason,
} from '../ApprovalView.styles'
import { splitTimeEntry } from '../../../entities/timeEntry/service'

const ActionButtons = ({
  onWritteOff,
  entry,
  selectedWeek,
  onReject,
  updateTimeEntryLocal,
  rates,
  onSplitEntry,
  client,
}) => {
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false)
  const handleRejectDialogClose = () => {
    setIsRejectDialogOpen(false)
  }
  const [entryToSplit, setEntryToSplit] = useState(null)

  const rejectTimeEntry = reason => {
    updateTimeEntryLocal(entry, 'rejected', reason).then(() => {
      onReject(entry, selectedWeek)
    })
  }

  const handleSplitEntry = entryId => {
    setEntryToSplit(entry)
  }

  return (
    <StyledButtonContainer>
      <StyledMutedButton
        onClick={() => {
          onWritteOff(entry)
        }}
      >
        Write Off
      </StyledMutedButton>
      <StyledMutedButton onClick={() => handleSplitEntry(entry?._id)}>
        Partial Write Off
      </StyledMutedButton>
      <StyledMutedButton onClick={() => setIsRejectDialogOpen(true)}>Send Back</StyledMutedButton>

      <RejectDialog
        open={isRejectDialogOpen}
        onCancel={handleRejectDialogClose}
        onAccept={reason => {
          rejectTimeEntry(reason)
          handleRejectDialogClose()
        }}
      />
      <SplitEntriesDialog
        open={entryToSplit}
        onAccept={async (timeEntry, durationInHours) => {
          const result = await splitTimeEntry({
            client,
            timeEntryId: timeEntry._id,
            durationInHours,
          })
          setEntryToSplit(null)
          onSplitEntry(result)
        }}
        entryToSplit={entryToSplit}
        onCancel={() => setEntryToSplit(null)}
        rates={rates}
      />
    </StyledButtonContainer>
  )
}

const RejectDialog = ({ open, onAccept, onCancel }) => {
  const [reason, setReason] = useState('')

  const handleAccept = () => {
    onAccept(reason)
    setReason('')
  }

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Reject Reason</DialogTitle>
      <DialogContent>
        <StyledRejectReason
          minRows={4}
          maxRows={10}
          label="Reason"
          variant="outlined"
          fullWidth
          value={reason}
          onChange={e => setReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <StyledMutedButton onClick={onCancel}>Cancel</StyledMutedButton>
        <StyledApproveButton onClick={handleAccept}>Confirm</StyledApproveButton>
      </DialogActions>
    </Dialog>
  )
}

const splitEntry = entry => {
  return [
    {
      ...entry,
      durationInHours: (entry.durationInHours / 2).toFixed(2),
    },
    {
      ...entry,
      durationInHours: (entry.durationInHours / 2).toFixed(2),
    },
  ]
}

const SplitEntriesDialog = ({ open, onAccept, onCancel, entryToSplit, rates }) => {
  const [entries, setEntries] = useState([])
  useEffect(() => {
    if (entryToSplit) {
      setEntries(splitEntry(entryToSplit))
    }
  }, [entryToSplit])

  const handelChangeHorus = (idx, value) => {
    const newEntries = [...entries]
    newEntries[idx].durationInHours = value
    setEntries(newEntries)
  }

  if (!open) return null
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Split entries</DialogTitle>
      <DialogContent>
        <Table aria-label="simple table">
          <StyledTableHead>
            <TableRow>
              <TableCell width={200}>Comment</TableCell>
              <TableCell
                style={{
                  minWidth: '150px',
                }}
                width={200}
              >
                Write off?
              </TableCell>
              <TableCell
                style={{
                  minWidth: '150px',
                }}
                width={200}
              >
                Total( Rate * Hours){' '}
              </TableCell>
              <TableCell width={50}>Hours</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {entries.map((entry, idx) => (
              <TableRow>
                <TableCell>{entry.comment}</TableCell>
                <TableCell>{idx ? 'Write Off' : 'Bill'}</TableCell>
                <TableCell>
                  {rates[entry.personId]} * {entry.durationInHours} = &nbsp;
                  {(entry.durationInHours * rates[entry.personId]).toFixed(2)}
                </TableCell>
                <TableCell width={100}>
                  <Input
                    type="number"
                    value={entry.durationInHours}
                    onChange={ev => handelChangeHorus(idx, ev.target.value)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <StyledMutedButton onClick={onCancel}>Cancel</StyledMutedButton>
        <StyledApproveButton
          onClick={() =>
            onAccept(entryToSplit, [+entries[0].durationInHours, +entries[1].durationInHours])
          }
        >
          Confirm
        </StyledApproveButton>
      </DialogActions>
    </Dialog>
  )
}

SplitEntriesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  entryToSplit: PropTypes.object.isRequired,
  rates: PropTypes.object.isRequired,
}

ActionButtons.propTypes = {
  onWritteOff: PropTypes.func.isRequired,
  entry: PropTypes.object.isRequired,
  selectedWeek: PropTypes.string.isRequired,
  onReject: PropTypes.func.isRequired,
  updateTimeEntryLocal: PropTypes.func.isRequired,
  rates: PropTypes.object.isRequired,
}

RejectDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default ActionButtons

import { useCallback, useEffect, useReducer } from 'react'
import moment from 'moment'
import Project from '../../server/models/project'
import { getTimeEntriesByProject } from '../../entities/timeEntry/service'
import * as peopleService from '../../entities/people/service'
import Assignment from '../../server/models/assignment'
import { getAllTimesheets } from '../../entities/timesheet/service'
import { fetchAssignmentsByProjectIdsInDateRange } from '../../entities/assignment/service'
import { getRatesByProjectLead, getExpensesByProjectIds } from '../../entities/project/service'
import { approveExpenseBillingStatus } from '../../entities/expenses/service'

const initialState = {
  people: [],
  projects: [],
  timesheets: {
    submitted: [],
    open: [],
  },
  assignments: [],
  entries: [],
  availableWeeks: [],
  rates: {},
  expenses: [],
}

const approvalsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PEOPLE':
      return { ...state, people: action.payload }
    case 'SET_PROJECTS':
      return { ...state, projects: action.payload }
    case 'SET_TIMESHEETS':
      return { ...state, timesheets: action.payload }
    case 'SET_ASSIGNMENTS':
      return { ...state, assignments: action.payload }
    case 'SET_ENTRIES':
      return { ...state, entries: action.payload }
    case 'SET_AVAILABLE_WEEKS':
      return { ...state, availableWeeks: action.payload }
    case 'SET_RATES':
      return { ...state, rates: action.payload }
    case 'SET_EXPENSES':
      return { ...state, expenses: action.payload }
    default:
      return state
  }
}
// Get available weeks in a lapse of time if a time entry is present
// Return {start: Date, end: Date, stringWeek: 'Week of 02/02/2025'}[]
const getMonthWeeks = (startDate, endDate, timesheets) => {
  const weeks = []
  const start = moment(startDate).startOf('month')
  const end = moment(endDate).endOf('month')
  const currentWeek = start.clone().startOf('week')
  while (currentWeek.isBefore(end)) {
    if (
      timesheets.some(ts => {
        return (
          moment(ts.startDate).isBetween(currentWeek, currentWeek.clone().endOf('week')) &&
          ts.entries.length > 0 &&
          ts.entries.some(entry => entry.approveState?.status === 'pending')
        )
      })
    ) {
      weeks.push({
        start: currentWeek.clone(),
        end: currentWeek.clone().endOf('week'),
        stringWeek: `Week of ${currentWeek.clone().format('MM/DD/YYYY')}`,
      })
    }

    currentWeek.add(1, 'week')
  }
  return weeks
}

const getOldestDate = dates => {
  return dates.reduce((acc, date) => {
    return date < acc ? date : acc
  }, new Date())
}

const getNewestDate = dates => {
  return dates.reduce((acc, date) => {
    return date > acc ? date : acc
  }, new Date(0))
}

const getLeadingProjects = async () => {
  const userAssignments = await Assignment.toArray()
  const leadingAssignments = userAssignments.filter(assignment => assignment.type !== 0)
  const allProjects = await Project.toArray()
  return allProjects.filter(project => {
    const notFixedBill = project.isFixedBill === 'false'

    return leadingAssignments.some(
      assignment => assignment.projectId === project._id && notFixedBill,
    )
  })
}

const useApprovalsData = ({ client, person }) => {
  const [state, dispatch] = useReducer(approvalsReducer, initialState)

  useEffect(() => {
    getRatesByProjectLead(client, person._id).then(result => {
      dispatch({ type: 'SET_RATES', payload: result?.rates })
    })
  }, [])

  useEffect(() => {
    getLeadingProjects().then(leadingProjects => {
      dispatch({ type: 'SET_PROJECTS', payload: leadingProjects })
    })
  }, [])

  useEffect(() => {
    if (!state.projects.length) return
    const projectIds = state.projects.map(project => project._id).join(',')

    const fetchAssignments = async () => {
      const projectAssignments = await fetchAssignmentsByProjectIdsInDateRange({
        projectIds,
        client,
        endISO: new Date().toISOString(),
        startISO: new Date(0).toISOString(),
      })

      const uniquePeopleIds = new Set(projectAssignments.map(assignment => assignment.personId))
      const peopleData = await peopleService.getAllPeople(client, {
        peopleIds: [...uniquePeopleIds],
      })

      const peopleHash = peopleData.reduce((acc, personData) => {
        acc[personData._id] = personData
        return acc
      }, {})

      const filteredAssignments = projectAssignments.filter(assignment => {
        return assignment.personId in peopleHash
      })

      dispatch({ type: 'SET_PEOPLE', payload: peopleHash })
      dispatch({ type: 'SET_ASSIGNMENTS', payload: filteredAssignments })
    }

    const fetchExpenses = async () => {
      const expenses = await getExpensesByProjectIds(client, projectIds)

      dispatch({ type: 'SET_EXPENSES', payload: expenses?.expenses || [] })
    }

    fetchExpenses()
    fetchAssignments()
  }, [state.projects])
  useEffect(() => {
    if (!state.projects.length || !Object.values(state.people).length) return

    const getTimeSheets = async () => {
      const moment2MonthsAgo = moment().subtract(2, 'months')
      const endDate = moment().toISOString()

      const timesheets = await getAllTimesheets({
        variables: {
          personIds: Object.keys(state.people),
          startDate: moment2MonthsAgo.startOf('month').toISOString(),
          endDate,
        },
        client,
      })

      const submittedTimesheets = timesheets.filter(ts => ts.approvalStatus === 'Approved')
      const openTimesheets = timesheets.filter(ts => ts.approvalStatus !== 'Approved')

      const pendingTimeEntries = await getTimeEntriesByProject({
        projectId: state.projects.map(project => project._id).join(','),
        approveState: 'pending',
        client,
      })

      const approvedTimeEntries = await getTimeEntriesByProject({
        projectId: state.projects.map(project => project._id).join(','),
        approveState: 'approved',
        client,
      })

      const pendingTimesheetsWithEntries = submittedTimesheets.map(timesheet => {
        const entries = [...pendingTimeEntries, ...approvedTimeEntries].filter(
          entry => entry.repliconTimesheetId === timesheet.repliconId,
        )
        return { ...timesheet, entries }
      })

      const oldestTimeEntryDate = getOldestDate(
        submittedTimesheets.map(ts => moment.utc(ts.startDate)),
      )

      const newestTimeEntryDate = getNewestDate(
        submittedTimesheets.map(ts => moment.utc(ts.startDate)),
      )

      const availableWeeks = getMonthWeeks(
        oldestTimeEntryDate,
        newestTimeEntryDate,
        pendingTimesheetsWithEntries,
      )

      dispatch({
        type: 'SET_ENTRIES',
        payload: [...pendingTimeEntries, ...approvedTimeEntries],
      })

      dispatch({
        type: 'SET_AVAILABLE_WEEKS',
        payload: availableWeeks,
      })

      dispatch({
        type: 'SET_TIMESHEETS',
        payload: { submitted: pendingTimesheetsWithEntries, open: openTimesheets },
      })
    }
    getTimeSheets()
  }, [state.projects, state.people])

  const onEntryUpdate = useCallback(
    entry => {
      const projectEntries = state.entries[entry.projectId]

      const updatedEntries = projectEntries.map(projectData => {
        if (entry.personId !== projectData.personId) return projectData
        const updatedTimeEntries = projectData.timeEntries.filter(projectEntry => {
          return projectEntry._id !== entry._id
        })

        return { ...projectData, timeEntries: updatedTimeEntries }
      })
      dispatch({
        type: 'SET_ENTRIES',
        payload: {
          ...state.entries,
          [entry.projectId]: updatedEntries,
        },
      })
    },
    [state.entries],
  )
  const onUpdateManyEntries = useCallback(
    entries => {
      const copyEntries = [...state.entries]

      entries.forEach(updatedEntry => {
        const existingEntry = copyEntries.findIndex(copyEntry => copyEntry._id === updatedEntry._id)
        if (existingEntry === -1) {
          copyEntries.push({ ...updatedEntry })
          return
        }

        copyEntries[existingEntry] = { ...updatedEntry }
      })

      dispatch({
        type: 'SET_ENTRIES',
        payload: copyEntries,
      })
    },
    [state.entries],
  )

  const onWritteOff = useCallback(
    (entry, revert = false) => {
      const updatedEntries = [...state.entries]

      const entryIdx = updatedEntries.findIndex(personEntry => personEntry._id === entry._id)
      const status = revert ? 'RAW' : 'WRITEOFF'
      updatedEntries[entryIdx] = { ...entry, status }

      dispatch({
        type: 'SET_ENTRIES',
        payload: updatedEntries,
      })
    },
    [state.entries],
  )

  const onReject = useCallback(
    entry => {
      const updatedEntries = state.entries.filter(personEntry => personEntry._id !== entry._id)

      dispatch({
        type: 'SET_ENTRIES',
        payload: updatedEntries,
      })
    },
    [state.entries],
  )

  const onSplitEntry = useCallback(
    ({ parent, child }) => {
      onUpdateManyEntries([parent, child])
    },
    [onUpdateManyEntries],
  )

  const onApproveExpenseBillingStatus = useCallback(
    (expenseQuickbooksId, billingStatus, personId) => {
      const approveState = {
        status: 'approved',
        modifiedBy: personId,
        modifiedOn: new Date(),
      }

      approveExpenseBillingStatus(client, expenseQuickbooksId, billingStatus, approveState)

      const updatedExpenses = [...state.expenses]
      const expenseIndex = state.expenses.findIndex(
        expense => expense.quickbooksId === expenseQuickbooksId,
      )
      const expense = { ...state.expenses[expenseIndex], billingStatus, approveState }
      updatedExpenses[expenseIndex] = expense

      dispatch({
        type: 'SET_EXPENSES',
        payload: updatedExpenses,
      })
    },
    [state.expenses],
  )

  return {
    state,
    actions: {
      onEntryUpdate,
      onUpdateManyEntries,
      onWritteOff,
      onReject,
      onSplitEntry,
      onApproveExpenseBillingStatus,
    },
  }
}

export default useApprovalsData

import React, { useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ShareIcon from '@material-ui/icons/Share'
import Typography from '@material-ui/core/Typography'

const StyledFormControl = styled(FormControl)`
  margin: 0;
  min-width: 120px;
`
const StyledInputLabel = styled(InputLabel)`
  color: ${({ theme }) => theme.colors.gray[700]};
  font-size: 1.5rem;

  &.Mui-focused {
    color: ${({ theme }) => theme.colors.gray[700]};
  }
`

const StyledSelect = styled(Select)`
  color: ${({ theme }) => theme.colors.gray[900]};
  font-size: 1rem;
  margin-top: 1rem;
`

export const WipReportPreviewDropdown = ({ onChange }) => {
  const [previewType, setPreviewType] = useState()

  const handleChange = event => {
    if (onChange) onChange(event.target.value)
    setPreviewType(event.target.value)
  }

  return (
    <StyledFormControl>
      <StyledInputLabel id="wip-select-label">
        <div style={{ display: 'flex' }}>
          <ShareIcon htmlColor="#6750A4" fontSize='medium'/>

          <Typography>
            <strong> Share WIP</strong>
          </Typography>
        </div>
      </StyledInputLabel>
      <StyledSelect
        labelId="wip-select-label"
        id="wip-select"
        value={previewType}
        onChange={handleChange}
      >
        <MenuItem value="summary">Summary WIP</MenuItem>
        <MenuItem value="detailed">Detailed WIP</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  )
}

WipReportPreviewDropdown.propTypes = {
  onChange: PropTypes.func,
}

export default WipReportPreviewDropdown

import { APPROVE_EXPENSE_BILLING_STATUS } from "./query"

export const approveExpenseBillingStatus = async (client, expenseQuickbooksId, billingStatus, approveState) => {
  const response = await client.query({
    query: APPROVE_EXPENSE_BILLING_STATUS,
    variables: { expenseQuickbooksId, billingStatus, approveState },
  })

  return response.data?.approveExpenseBillingStatus
}

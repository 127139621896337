import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _ from 'lodash'
import timeUtils from '../../../../utils/timeUtils'
import { HtmlTooltip } from './styles'
import { APPROVED } from '../../../../constants/timesheetStatus'
import TooltipContent from './TooltipContent'
import moment from 'moment'

const DayTooltip = ({ timesheet, date, pendingEntries }) => {
  const currentDatePendingHours = useMemo(
    () =>
      pendingEntries?.reduce((totalHours, entry) => {
        const entryDate = moment.utc(entry.entryDate);
        const currentDate = moment.utc(date);
  
        if (entryDate.isSame(currentDate, 'day')) {
          return totalHours + entry.durationInHours; 
        }
        return totalHours;
      }, 0),
    [date, pendingEntries],
  );

  const isSubmitted = !_.isNil(timesheet) && timesheet.approvalStatus === APPROVED
  const isDraft =
    !_.isNil(currentDatePendingHours) && !isSubmitted && currentDatePendingHours > 0
  const dayCellClass = classNames({
    'DayPicker-Tooltip': true,
    submitted: isSubmitted,
    opened: !isSubmitted && !isDraft,
    draft: isDraft,
  })

  const formatedDayYYYYMMDD = timeUtils.formatDateToYYYYMMDD(date)

  return (
    <HtmlTooltip
      arrow
      title={
        <TooltipContent
          isSubmitted={isSubmitted}
          totalHours={isSubmitted ? timesheet.totalHours : currentDatePendingHours}
          date={date}
          isDraft={isDraft}
        />
      }
    >
      <div data-testid={`datePicker.day.${formatedDayYYYYMMDD}`} className={dayCellClass}>
        {date.getDate()}
      </div>
    </HtmlTooltip>
  )
}

DayTooltip.propTypes = {
  date: PropTypes.object.isRequired,
  timesheet: PropTypes.object,
}

export default DayTooltip

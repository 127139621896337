import _ from 'lodash'
import jwt from 'jsonwebtoken'
import {
  setPicture,
  setGlimpseApiToken,
  setFunnelUser,
  getLastOutlookEventSyncDate,
  getLastOutlookMessageSyncDate,
  getOutlookEventDeltaToken,
  getOutlookMessageDeltaToken,
  getFunnelUser,
  getPicture,
  clearStore,
  getGlimpseApiToken,
} from '../../server/store'

import store from '../store'
import Auth from '../auth'

import db from '../../server/db'

import { getDurationInHours } from '../../server/utils/date'
import { log, logError } from '../../utils/logger'
import config from '../../config'
import * as authService from '../../entities/auth/service'
import { fetchUserPicture } from '../../entities/userSettings/service'

import ipcRenderer from '../../ipcRenderer'

const { MAX_ELAPSED_HOURS_TO_SYNC_ALL } = config

const getAndSetGlimpseApiToken = async (auth0Client, client, silent, multiLogin) => {
  const glimpseApiToken = await authService.getGlimpseApiTokenMutation(
    auth0Client,
    silent,
    multiLogin,
  )
  const user = await getAndSetDataFromToken(glimpseApiToken, client)
  return user
}

export const getAndSetDataFromToken = async (glimpseApiToken, client) => {
  if (glimpseApiToken) setGlimpseApiToken(glimpseApiToken)
  else return null
  const decodedToken = jwt.decode(glimpseApiToken)
  const user = decodedToken['https://keystonestrategy.io/user']

  // If user is undefined, it means that auth0 couldn't find a valid profile in the database for the user
  if (!user) {
    throw new Error('A valid profile for the user could not be found. Please contact support.')
  }

  const picture = await savePictureToLocalStorage(client)

  setFunnelUser(user)
  if (ipcRenderer) {
    await ipcRenderer.invoke('setUserData', {
      glimpseApiToken,
      funnelPerson: user,
      userPicture: picture, // user picture will be fetched
    })
  }

  return { glimpseApiToken, person: user, picture }
}

const savePictureToLocalStorage = async client => {
  const response = await fetchUserPicture(client)
  if (response.hasPicture) {
    const picture = `data:${response.contentType};base64,${response.picture}`
    setPicture(picture)
    return picture
  }
  return null
}

export const loginService = async (auth0Client, client, silent, multiLogin = false) => {
  await deleteAllData()
  const data = await getAndSetGlimpseApiToken(auth0Client, client, silent, multiLogin)
  if (!data) return null
  const { person, picture } = data

  return {
    ...person,
    picture,
  }
}

export const loginByReinstallingService = async () => {
  try {
    if (!ipcRenderer) return null
    const userData = await ipcRenderer.invoke('getUserData')
    console.log('loginByReinstallingService', userData)
    if (!userData || !userData.glimpseApiToken) return null
    const { glimpseApiToken, userPicture, funnelPerson } = userData
    setGlimpseApiToken(glimpseApiToken)
    setFunnelUser(funnelPerson)
    setPicture(userPicture)
    log('redux > services > auth > loginByReinstallingService')
    return {
      ...funnelPerson,
      picture: userPicture,
    }
  } catch (e) {
    logError(e, 'redux > services > auth > loginByReinstallingService > e: ')
  }
}

// will be removed in next commit
const isOutlookDeltaTokensValid = () => {
  const lastEventSyncDate = getLastOutlookEventSyncDate()
  const lastMessageSyncDate = getLastOutlookMessageSyncDate()
  const lastSyncDate =
    lastEventSyncDate < lastMessageSyncDate ? lastEventSyncDate : lastMessageSyncDate
  const doesDeltaTokenExist =
    !_.isEmpty(getOutlookEventDeltaToken()) && !_.isEmpty(getOutlookMessageDeltaToken())
  if (!lastSyncDate || !doesDeltaTokenExist) return false
  const timeElapsed = getDurationInHours(lastSyncDate, new Date())
  return Number(timeElapsed) < MAX_ELAPSED_HOURS_TO_SYNC_ALL
}

export const calcIsUserLoggedIn = async auth0 => {
  const token = await getLatestGlimpseToken(auth0)
  const isLoggedIn = !!token && isOutlookDeltaTokensValid()
  const person = getFunnelUser()
  const picture = getPicture()
  return {
    userInfo: {
      ...person,
      picture,
    },
    isLoggedIn,
  }
}

export const deleteAllData = async () => {
  if (ipcRenderer) await ipcRenderer.invoke('deleteUserData')
  try {
    localStorage.clear()
    await db.delete()
    await db.open()
  } catch (e) {
    logError(e, 'DeletionError: There was an error deleting the indexdb store:')
  }
  clearStore()
}

const fetchNewtokenPromiseQueue = []

const resolveFetchNewtokenPromiseQueue = (token, error = null) => {
  if (fetchNewtokenPromiseQueue.length > 1) {
    for (const promise of fetchNewtokenPromiseQueue) {
      if (promise !== null)
        if (token !== null) promise.resolve(token)
        else promise.reject(error)
    }
  }
  fetchNewtokenPromiseQueue.splice(0, fetchNewtokenPromiseQueue.length)
}

const fetchNewToken = async auth0 => {
  if (fetchNewtokenPromiseQueue.length > 0)
    return new Promise((resolve, reject) => fetchNewtokenPromiseQueue.push({ resolve, reject }))

  // Pushing null to force other apiCalls to wait for this fetch
  fetchNewtokenPromiseQueue.push(null)

  try {
    let token
    if (ipcRenderer) {
      token = await ipcRenderer.invoke('auth0Login')
    } else {
      token = await auth0.getAccessTokenSilently({
        audience: config.AUTH0_GLIMPSE_AUDIENCE,
        connection_scope: 'offline_access',
      })
    }

    // Resolving the queue
    resolveFetchNewtokenPromiseQueue(token)

    return token
  } catch (error) {
    resolveFetchNewtokenPromiseQueue(null, error)
    throw error
  }
}

export const getLatestGlimpseToken = async auth0 => {
  const tokenFromStore = getGlimpseApiToken()
  if (!tokenFromStore) return null
  const decodedToken = jwt.decode(tokenFromStore)
  const expiryAt = decodedToken.exp
  const now = Math.floor(Date.now() / 1000)
  if (now <= expiryAt - 60) return tokenFromStore
  // Token Expired, Let's fetch new
  store.dispatch(Auth.loginRequest())
  try {
    const tokenFromAuth0 = await fetchNewToken(auth0)
    setGlimpseApiToken(tokenFromAuth0)
    return tokenFromAuth0
  } catch (e) {
    if (e.message !== 'Login required') throw e
    await logout()
  }
}

const logout = async () => {
  store.dispatch(Auth.logoutRequest())
  await deleteAllData()
  if (ipcRenderer) {
    await ipcRenderer.send('delete-offline-entries')
    await ipcRenderer.send('auth0-logout')
  }
  store.dispatch(Auth.logoutSuccess())
}

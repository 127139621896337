import {
  GET_FILTERED_TIME_ENTRIES,
  GET_PROJECTS,
  GET_RATES_BY_PROJECT_LEAD,
  GET_EXPENSES_BY_PROJECT_IDS,
  GET_WIP_SUMMARY_REPORT_DOC,
  GET_WIP_DETAILED_REPORT_DOC,
  GET_WIP_REPORTS_BY_PROJECT_IDS,
  GET_PROJECT_INVOICES,
} from './query'

export const getProjects = async client => {
  const response = await client.query({
    query: GET_PROJECTS,
  })

  const projects = response.data.getProjects
  return projects
}

export const getTimeEntriesByProjectLeader = async client => {
  const response = await client.query({
    query: GET_FILTERED_TIME_ENTRIES,
  })
  const timeEntriesByProjectLead = response.data.getTimeEntriesByProjectLeader
  return timeEntriesByProjectLead
}

export const getRatesByProjectLead = async (client, projectLeadId) => {
  const response = await client.query({
    query: GET_RATES_BY_PROJECT_LEAD,
    variables: { projectLeadId },
  })

  return response.data?.getRatesByProjectLead
}

export const getExpensesByProjectIds = async (client, projectIds) => {
  const response = await client.query({
    query: GET_EXPENSES_BY_PROJECT_IDS,
    variables: { projectIds },
  })

  return response.data?.getExpensesByProjectIds
}

export const getWipSummaryReportDoc = async (client, project, wipData, person) => {
  const response = await client.query({
    query: GET_WIP_SUMMARY_REPORT_DOC,
    variables: { project, wipData, person },
  })

  return response.data?.getWipSummaryReportDoc
}

export const getWipDetailedReportDoc = async (client, project, wipData, person) => {
  const response = await client.query({
    query: GET_WIP_DETAILED_REPORT_DOC,
    variables: { project, wipData, person },
  })

  return response.data?.getWipDetailedReportDoc
}

export const getWipReportsByProjectIds = async (client, projectIds, months) => {
  const response = await client.query({
    query: GET_WIP_REPORTS_BY_PROJECT_IDS,
    variables: { projectIds, months },
  })

  return response.data?.getWipReportsByProjectIds
}

export const getProjectInvoices = async (client, projectId) => {
  const response = await client.query({
    query: GET_PROJECT_INVOICES,
    variables: { projectId },
  })

  return response.data?.getProjectInvoices
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import TableCell from '@material-ui/core/TableCell'

import PropTypes from 'prop-types'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import {
  ApprovalsPeopleContainer,
  ApprovalViewContainer,
  BudgetContainer,
  DetailsActionContainer,
  StyledApproveButton,
  StyledMutedButton,
  StyledWeekContainer,
  TableWrapper,
  WeekContainer,
} from '../Approvals/ApprovalView.styles'
import { ProjectsTableContainer, StyledTableHead } from '../Approvals/ProjectsView.styles'
import { WeekTabButton } from '../Calendar/Header/styles'
import { calculateBudgetAndPacingInWeek, groupTimeEntries } from '../Approvals/Approvals.utils'
import ProjectSelection from '../../components/ProjectSelection'
import { LoadingIndicator } from '../Calendar/style'
import moment from 'moment'

const ApprovalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

document.body.style.overflow = 'auto'

const getExpensesAvailableWeeks = expenses => {
  const weeks = {}

  expenses.forEach(expense => {
    const date = moment(expense.incurredDate)
    const week = date.clone().startOf('week')
    const formattedWeek = week.clone().format('MM/DD/YYYY')

    weeks[formattedWeek] = {
      start: week,
      end: date.clone().endOf('week'),
      stringWeek: `Week of ${formattedWeek}`,
    }
  })

  return Object.entries(weeks).map(([_, week]) => week)
}

const ExpensesView = ({
  projects,
  expenses,
  timeEntries: rawTimeEntries,
  timesheets,
  people,
  person,
  availableWeeks: allWeeks = [],
  rates,
  onProjectSelect,
  onApproveExpenseBillingStatus,
}) => {
  const [selectedProject, setSelectedProject] = useState(projects[0])

  const projectExpenses = useMemo(
    () => expenses.filter(expense => expense.projectId === selectedProject._id),
    [selectedProject, expenses],
  )

  const availableWeeks = useMemo(() => getExpensesAvailableWeeks(projectExpenses), [
    projectExpenses,
  ])

  const [selectedWeek, setSelectedWeek] = useState(availableWeeks[0])

  const selectedWeekExpenses = useMemo(
    () =>
      projectExpenses.filter(expense => {
        if (!selectedWeek) return false
        return moment.utc(expense.incurredDate).isoWeek() === selectedWeek.start.isoWeek()
      }),
    [projectExpenses, selectedWeek],
  )

  const selectedWeekPendingExpenses = useMemo(
    () =>
      selectedWeekExpenses.filter(expense => {
        if (!expense.approveState) return true
        return expense.approveState.status === 'pending'
      }),
    [selectedWeekExpenses],
  )

  const timeEntries = useMemo(() => {
    return groupTimeEntries(rawTimeEntries, timesheets.submitted, allWeeks)
  }, [timesheets, rawTimeEntries, allWeeks])

  useEffect(() => {
    for (let i = 0; i < projects.length; i++) {
      const peopleEntries = timeEntries?.[selectedProject?._id]?.[selectedWeek?.stringWeek]
      if (peopleEntries) {
        setSelectedProject(projects[i])
        onProjectSelect(projects[i])
        break
      }
    }
  }, [projects])

  const setProject = id => {
    setSelectedProject(projects.find(project => project._id === id))
  }

  const peopleEntries =
    (timeEntries &&
      selectedProject &&
      selectedWeek &&
      timeEntries[selectedProject?._id]?.[selectedWeek?.stringWeek]) ||
    {}

  const { actualPacing, actualUsed, weekBudget } = useMemo(() => {
    if (!timeEntries || !selectedProject || !selectedWeek) return {}
    return calculateBudgetAndPacingInWeek(
      selectedWeek.start,
      selectedProject,
      peopleEntries,
      selectedWeekExpenses,
      rates,
    )
  }, [timeEntries, selectedProject, selectedWeek, selectedWeekExpenses])

  if (!selectedProject)
    return (
      <LoadingIndicator>
        <CircularProgress />
      </LoadingIndicator>
    )

  return (
    <ApprovalsContainer>
      <ApprovalViewContainer>
        <div>
          {selectedProject && (
            <ProjectSelection
              projects={projects}
              selectedProject={selectedProject?._id}
              setSelectedProject={project => {
                setProject(project)
                onProjectSelect(project)
              }}
            />
          )}
          <div style={{ marginBottom: 30 }} />
        </div>
        <ApprovalsPeopleContainer>
          {weekBudget && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: 20,
                width: '100%',
                marginBottom: 20,
              }}
            >
              <BudgetContainer>
                <Typography>
                  <strong>Project Budget (Week)</strong>
                </Typography>
                <Typography>{weekBudget}</Typography>
              </BudgetContainer>
              <BudgetContainer>
                <Typography>
                  <strong>Project Actual (Week)</strong>
                </Typography>
                <Typography>{actualUsed}</Typography>
              </BudgetContainer>
              <BudgetContainer>
                <Typography>
                  <strong> Pacing</strong>{' '}
                </Typography>
                <Typography>{actualPacing}%</Typography>
              </BudgetContainer>
            </div>
          )}

          <StyledWeekContainer>
            {availableWeeks.length > 0 &&
              availableWeeks.map(week => {
                return (
                  <WeekTabButton
                    key={week.stringWeek}
                    minWidth="200px"
                    onClick={() => {
                      setSelectedWeek(week)
                    }}
                    selected={week.stringWeek === selectedWeek?.stringWeek}
                  >
                    {week.stringWeek}
                  </WeekTabButton>
                )
              })}
          </StyledWeekContainer>

          <WeekContainer>
            <TableWrapper>
              <TableContainer component={ProjectsTableContainer} rows={5}>
                <Table aria-label="simple table">
                  <StyledTableHead>
                    <TableRow>
                      <TableCell align="Left">Staff</TableCell>
                      <TableCell align="center">Description</TableCell>
                      <TableCell align="center">Category</TableCell>
                      <TableCell align="center">Cost</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </StyledTableHead>
                  {selectedWeekPendingExpenses.map(expense => {
                    return (
                      <TableBody>
                        <TableRow>
                          <TableCell align="left">{people[expense.personId]?.fullName}</TableCell>
                          <TableCell align="center">{expense.memo}</TableCell>
                          <TableCell align="center">{expense.category}</TableCell>
                          <TableCell align="center">{expense.amount}</TableCell>
                          <TableCell align="center">
                            {new Date(expense.incurredDate).toLocaleDateString()}
                          </TableCell>
                          <TableCell align="center">
                            <DetailsActionContainer>
                              <StyledApproveButton
                                onClick={() => {
                                  onApproveExpenseBillingStatus(expense.quickbooksId, 'Billable', person._id)
                                }}
                              >
                                Billable
                              </StyledApproveButton>
                              <StyledMutedButton
                                onClick={() => {
                                  onApproveExpenseBillingStatus(expense.quickbooksId, 'NotBillable', person._id)
                                }}
                              >
                                Non-Billable
                              </StyledMutedButton>
                            </DetailsActionContainer>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )
                  })}
                </Table>
              </TableContainer>
            </TableWrapper>
          </WeekContainer>
        </ApprovalsPeopleContainer>
      </ApprovalViewContainer>
    </ApprovalsContainer>
  )
}

ExpensesView.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object),
  client: PropTypes.object.isRequired,
  onEntryUpdate: PropTypes.func.isRequired,
  onUpdateManyEntries: PropTypes.func.isRequired,
  timeEntries: PropTypes.object,
  availableWeeks: PropTypes.object,
  people: PropTypes.object,
  timesheets: PropTypes.object,
  person: PropTypes.object,
  onWritteOff: PropTypes.func,
  onReject: PropTypes.func,
  rates: PropTypes.object,
  calendarValues: PropTypes.object,
  calendarResume: PropTypes.object,
  onSplitEntry: PropTypes.func,
  onProjectSelect: PropTypes.func,
}

export default ExpensesView

import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import MenuSection from '../../components/MenuSection'
import Approvals from '../Approvals/ApprovalsPage'
import WorkInProgressPage from '../WorkInProgress/WorkInProgressPage'

const LeadView = ({ person, client }) => {
  const location = useLocation()
  const getSelectedTab = () => {
    if (location.pathname.includes('/lead-view/approvals')) {
      return 'approvals'
    }
    if (location.pathname.includes('/lead-view/work-in-progress')) {
      return 'wip'
    }
    return 'lead-view'
  }

  return (
    <div>
      <Switch>
        <Route
          path="/lead-view/approvals"
          render={() => <Approvals person={person} client={client} />}
        />
        <Route
          path="/lead-view/work-in-progress"
          render={() => <WorkInProgressPage person={person} client={client} />}
        />
      </Switch>
    </div>
  )
}

LeadView.propTypes = {
  person: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
}

export default LeadView

import React from 'react'
import { FormControl, MenuItem } from '@material-ui/core'
import { StyledFormLabel, StyledSelect } from './style'

const ProjectSelection = ({ projects, selectedProject, setSelectedProject }) => {
  return (
    <FormControl>
      <StyledFormLabel id="project-selct-group-label">Select Project</StyledFormLabel>
      <StyledSelect
        labelId="project-select-label"
        id="project-select"
        value={selectedProject}
        onChange={e => setSelectedProject(e.target.value)}
      >
        {projects.map(project => (
          <MenuItem key={project._id} value={project._id}>
            {project.name}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  )
}

export default ProjectSelection
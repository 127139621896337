import React from 'react'
import PropTypes from 'prop-types'

import { isInSyncedRange } from '../../utils'
import { Column, ApprovalStatus, Row, TooltipContainer } from './styles'
import { Blue } from '../../../../styledComponents/text'

const TooltipContent = ({ isSubmitted, totalHours, date, isDraft }) => {
  const approvalStatusText = isSubmitted ? 'Submitted' : 'Unsubmitted'

  return !isSubmitted && !isDraft ? (
    <ClickToSync date={date} />
  ) : (
    <TooltipContainer>
      <ApprovalStatus isSubmitted={isSubmitted}>{approvalStatusText}</ApprovalStatus>
      <Row>
        <span>{totalHours}</span>
        <Blue>Hours</Blue>
      </Row>
    </TooltipContainer>
  )
}

const ClickToSync = ({ date }) => {
  const tooltipLabel = isInSyncedRange(date) ? 'Load' : 'Sync'
  return (
    <TooltipContainer>
      <Column>
        <Blue>Click to {tooltipLabel}</Blue>
        <Blue>Timesheet</Blue>
      </Column>
    </TooltipContainer>
  )
}

ClickToSync.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
}

TooltipContent.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  totalHours: PropTypes.number,
}

export default TooltipContent

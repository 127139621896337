import styled, { css } from 'styled-components'
import Button from '@material-ui/core/Button'
import { Accordion, TextareaAutosize } from '@material-ui/core'

export const StyledApproveButton = styled(Button)(
  ({ theme: { colors } }) => css`
    background-color: ${colors.nightshade.light};
    color: white;
    padding: 0.2rem 0.6rem;
    border-radius: 10px;
    width: auto;
    font-size: 0.8rem;
    text-transform: capitalize;

    &:hover {
      background-color: ${colors.nightshade.medium};
    }
  `,
)

export const StyledMutedButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.gray[700]};
  color: white;
  padding: 0.2rem 1.2rem;
  border-radius: 10px;
  position: relative;
  text-transform: capitalize;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[500]};
  }
`
export const StyledRejectButtonMin = styled(Button)`
  background-color: ${({ theme }) => theme.colors.gray[300]};
  color: white;
  padding: 0.2rem;
  border-radius: 10px;
  position: relative;
  font-size: 0.7rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[500]};
  }
`

export const StyledButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
`

export const StyledRejectContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  width: 200px;
`

export const StyledAccordion = styled(Accordion)`
  border-top: none;
  border-left: none;
  border-right: none;
  width: 80%;
  max-width: 1000px;
`

export const ApprovalsPeopleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
  width: 100%;
  height: 100%;
`

export const StyledRejectReason = styled(TextareaAutosize)({
  marginTop: '10px',
  maxWidth: '600px',
  maxHeight: '600px',
})

export const StyledDetailsButton = styled(Button)(
  ({ theme: { colors } }) => css`
    background-color: ${colors.lilac.medium};
    color: white;
    padding: 0.2rem;
    border-radius: 10px;

    &:hover {
      background-color: ${colors.lilac.dark};
    }
  `,
)

export const ApprovalViewContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
`
export const DetailsViewContainer = styled.div`
  display: block;
  width: 80%;
  margin-top: 50px;
  position: relative;
`
export const DetailsViewHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.paper};
  padding: 20px;
`
export const StyledDetailsSpan = styled.span`
  display: inline-block;
  width: auto;
`
export const StyledContainerModal = styled.div`
  display: block;
  width: 80%;
  position: absolute;
  background-color: #ffffff;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #000000;
`

export const StyledWeekContainer = styled.div`
  display: flex;
  gap: 0;
  overflow-x: auto;
  overflow-y: hidden;
  width: 80%;
  max-width: 1000px;
  height: 70px;
  justify-content: flex-start;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
`

export const TabsContainer = styled.div`
  display: inline-flex;
  border: 1px solid #ccc;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
`

export const ApplicationTab = styled(Button)(
  ({ selected }) => css`
    flex: 1;
    text-align: center;
    padding: 5px 12px;
    font-size: 1rem;
    color: ${selected ? '#fff' : '#000'};
    background-color: ${selected ? '#5f48dd' : '#fff'};
    border: none;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: ${selected ? '#5f48dd' : '#f2f2f2'};
    }

    &:focus {
      outline: none;
    }
  `,
)

export const BudgetContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.nightshade.medium};
  color: ${({ theme }) => theme.colors.paper || '#fff'};
  border: 1px solid ${({ theme }) => theme.colors.nightshade.dark};
  border-radius: 12px;
  padding: 8px 16px;
  width: 240px;

  /* Flex settings */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* Subtle shadow for depth */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  /* Hover effect (optional) */
  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }
`
export const WeekContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`

export const TableWrapper = styled.div`
  width: 80%;
  max-width: 1000px;
`

export const DetailsActionContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`

export const SummaryContextSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

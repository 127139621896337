import React from 'react'
import PropTypes from 'prop-types'

import { StyledPageContainer } from '../Approvals/Approvals.styles'
import { WorkInProgressContainer } from './WorkInProgress.styles'
import WorkInProgressView from './WorkInProgressView'

const WorkInProgressPage = ({ person, client, invoiceTotals, state }) => {
  const { projects, entries, people, expenses, rates } = state
  return (
    <StyledPageContainer>
      <WorkInProgressContainer>
        <WorkInProgressView
          client={client}
          projects={projects}
          entries={entries}
          people={people}
          expenses={expenses}
          rates={rates}
          person={person}
          invoiceTotals={invoiceTotals}
        />
      </WorkInProgressContainer>
    </StyledPageContainer>
  )
}

WorkInProgressPage.propTypes = {
  client: PropTypes.object.isRequired,

  person: PropTypes.object.isRequired,
}

export default WorkInProgressPage

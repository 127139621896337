import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles({
  headerCell: {
    fontSize: "1.2rem", 
    fontWeight: "bold", 
  },
});

const keyDatesRows = [
  {
    label: 'First draft of opening reports',
    startDate: '01/01/2025',
    endDate: '02/01/2025',
  },
  {
    label: 'Filing of opening reports',
    startDate: '01/01/2025',
    endDate: '02/01/2025',
  },  {
    label: 'Rebuttal reports',
    startDate: '01/01/2025',
    endDate: '02/01/2025',
  },  {
    label: 'Depositions',
    startDate: '01/01/2025',
    endDate: '02/01/2025',
  },  {
    label: 'Trial',
    startDate: '01/01/2025',
    endDate: '02/01/2025',
  },
];

const KeyDatesTable = () => {
  const classes = useStyles();
  
  return (
    <TableContainer component={Paper}>
      <Table aria-label="WIP Report Table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerCell}>Month to Date Summary</TableCell>
            <TableCell className={classes.headerCell}>Start Date</TableCell>
            <TableCell className={classes.headerCell}>End Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {keyDatesRows.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.label}</TableCell>
              <TableCell>{row.startDate}</TableCell>
              <TableCell>{row.endDate}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default KeyDatesTable;

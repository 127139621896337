import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import ColoredCell from './ColoredCell'
import { ksTheme } from '../../../theme'
import formatCurrency from '../../../utils/formatCurrency'
import moment from 'moment'

const useStyles = makeStyles({
  headerCell: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
})

const getPacingColor = pacingValue => {
  if (!pacingValue) return 'transparent'

  const pacing = parseFloat(pacingValue.replace('%', ''))

  if (pacing <= 100) {
    return ksTheme.colors.kellyGreen.light
  }

  return ksTheme.colors.red
}

const WipReportTable = ({ wipData, project }) => {
  const classes = useStyles()
  const tableData = {
    staffHours: {
      label: 'Staff Hours (Hrs)',
      submitted: wipData.submittedHours.staffHours,
    },
    staffRates: {
      label: 'Staff Rates',
      submitted: formatCurrency(wipData.submittedHours.staffRate, project.CurrencyIsoCode),
    },
    expertHours: {
      label: 'Expert Hours (Fees)',
      submitted: wipData.submittedHours.expertHours,
    },
    expertRates: {
      label: 'Expert Rates',
      submitted: formatCurrency(wipData.submittedHours.expertRate, project.CurrencyIsoCode),
    },
    staffExpenses: {
      label: 'Staff Expenses (Cost & Disbursements)',
      submitted: formatCurrency(wipData.submittedExpenses.staffExpenses, project.CurrencyIsoCode),
    },
    expertExpenses: {
      label: 'Expert Expenses (Cost & Disbursements)',
      submitted: formatCurrency(wipData.submittedExpenses.expertExpenses, project.CurrencyIsoCode),
    },
    totalFeeeAndExpenses: {
      label: 'Total Fees + Total Expenses',
      submitted: formatCurrency(wipData.totalFeesAndExpenses, project.CurrencyIsoCode),
      budgeted: formatCurrency(project.Budget__c, project.CurrencyIsoCode),
      pacing: wipData.wipPacing,
    },
    writeOffs: {
      label: 'Write Offs',
      submitted: formatCurrency(wipData.submittedWriteOffs, project.CurrencyIsoCode),
      budgeted: '',
      pacing: '',
    },
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="WIP Report Table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerCell}>Month to Date Summary</TableCell>
            <TableCell className={classes.headerCell}>Submitted</TableCell>
            <TableCell className={classes.headerCell}>Budgeted ({moment(wipData.month).format("MMMM")})</TableCell>
            <TableCell className={classes.headerCell}>Pacing</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(tableData).map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.label}</TableCell>
              <TableCell>{row.submitted}</TableCell>
              <TableCell>{row.budgeted}</TableCell>
              <ColoredCell backgroundColor={getPacingColor(row.pacing)}>{row.pacing}</ColoredCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

WipReportTable.propTypes = {
  wipData: PropTypes.object.isRequired,
}

export default WipReportTable

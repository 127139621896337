import React from 'react'
import PropTypes from 'prop-types'
import UserProfile from '../../views/Calendar/Header/UserProfile'
import { Container, MenuContainer } from '../../views/Calendar/Header/styles'
import { ApplicationTab, TabsContainer } from '../../views/Approvals/ApprovalView.styles'

const views = {
  approvals: 'approvals',
  expenses: 'expenses',
  projects: 'projects',
  wip: 'wip',
}

const MenuSection = ({ person, selectedTab, setSelectedTab, latestInvoice }) => {
  const stackInvoice = latestInvoice
    ? `https://test.stacks.keystonestrategy.io/invoices/${latestInvoice}`
    : `https://test.stacks.keystonestrategy.io/`
  return (
    <Container>
      <UserProfile person={person} />

      <MenuContainer
        style={{
          gap: '10px',
        }}
      >
        <TabsContainer>
          <ApplicationTab
            onClick={() => setSelectedTab(views.approvals)}
            selected={selectedTab === 'approvals'}
          >
            Time Approvals
          </ApplicationTab>

          <ApplicationTab
            onClick={() => setSelectedTab(views.expenses)}
            selected={selectedTab === 'expenses'}
          >
            Expense Approvals
          </ApplicationTab>

          <ApplicationTab
            onClick={() => setSelectedTab(views.wip)}
            selected={selectedTab === 'wip'}
          >
            WIP Report
          </ApplicationTab>
          
          <ApplicationTab href={stackInvoice} target="_blank" rel="noopener noreferrer" selected={selectedTab === 'invoices'}>
            Invoices
          </ApplicationTab>
        </TabsContainer>
      </MenuContainer>
    </Container>
  )
}

MenuSection.propTypes = {
  person: PropTypes.object.isRequired,
  selectedTab: PropTypes.string.isRequired,
}

export default MenuSection

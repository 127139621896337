import React, { useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledFormControl = styled(FormControl)`
  margin: 0;
  min-width: 80px;
`
const StyledInputLabel = styled(InputLabel)`
  color: ${({ theme }) => theme.colors.gray[700]};
  font-size: 1.5rem;

  &.Mui-focused {
    color: ${({ theme }) => theme.colors.gray[700]};
  }
`

const StyledSelect = styled(Select)`
  color: ${({ theme }) => theme.colors.gray[900]};
  font-size: 1rem;
  margin-top: 1rem;
`

export const BillableDropdown = ({ onChange, entry }) => {
  const [billable, setBillable] = useState(entry.billable)

  const handleChange = event => {
    if (onChange) onChange(event.target.value)
    setBillable(event.target.value)
  }

  return (
    <StyledFormControl>
      <StyledSelect
        value={billable}
        onChange={handleChange}
      >
        <MenuItem value="ProjectRate">Billable</MenuItem>
        <MenuItem value="NonBillable">Non Billable</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  )
}

BillableDropdown.propTypes = {
  onChange: PropTypes.func,
}

export default BillableDropdown

/* eslint-disable no-return-assign */
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import eventUtils from '../../../utils/eventUtils'
import GroupedEvent from './GroupedEvent'
import EventCard from './EventCard'
import { REFERENCE_TYPE } from '../../../constants/timeEntry'
import { isElementInViewport } from './utils'
import CircularProgress from '@material-ui/core/CircularProgress'
import { EventListLoadingContainer } from './style'

class EventList extends React.Component {
  state = {
    selectedEventId: null,
    highlightedEventId: null,
  }

  constructor(props) {
    super(props)
    this.itemRefs = {}
    this.eventListRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentSelectedEvent !== this.props.currentSelectedEvent) {
      setTimeout(() => this.scrollTo(this.props.currentSelectedEvent), 100)
    }
  }

  _handleSelectEvent = event => {
    this.setState({
      selectedEventId: event._id,
    })
  }

  /*
   *  Handle when an event is clicked on
   */

  _handleAddToEntryClick = event => {
    this.props.addToEntry(event)
    this._handleSelectEvent(event)
  }

  _handleCreateEntryClick = event => {
    this.props.createEntry(event)
    this._handleSelectEvent(event)
  }

  _isAlreadyUsedEvent = event => {
    return _.hasIn(this.props.usedExchangeEvents, event.id)
  }
  /*
   * Render the individual event item
   */

  renderEmailCard = (event, idx) => {
    const eventWithReferenceType = { ...event, referenceType: REFERENCE_TYPE.MAIL }
    return (
      <div ref={el => (this.itemRefs[event._id] = el)} key={idx}>
        <EventCard
          onSelect={() => this._handleSelectEvent(eventWithReferenceType)}
          onAddToEntry={() => this._handleAddToEntryClick(eventWithReferenceType)}
          onCreateEntry={() => this._handleCreateEntryClick(eventWithReferenceType)}
          event={eventWithReferenceType}
          isSelected={this.state.selectedEventId === event._id}
          isHighlighted={this.state.highlightedEventId === event._id}
          entries={this.props.entries}
          localEntries={this.props.localEntries}
          currentEditableEntry={this.props.currentEditableEntry}
          isEmail
        />
      </div>
    )
  }

  renderEventCard = (event, idx, date) => {
    const eventWithReferenceType = { ...event, referenceType: REFERENCE_TYPE.CALENDAR }
    return (
      <div ref={el => (this.itemRefs[event._id] = el)} key={idx}>
        <EventCard
          onSelect={() => this._handleSelectEvent(eventWithReferenceType)}
          onAddToEntry={() => this._handleAddToEntryClick(eventWithReferenceType)}
          onCreateEntry={() => this._handleCreateEntryClick(eventWithReferenceType)}
          event={eventWithReferenceType}
          date={date}
          isSelected={this.state.selectedEventId === event._id}
          isHighlighted={this.state.highlightedEventId === event._id}
          entries={this.props.entries}
          localEntries={this.props.localEntries}
          currentEditableEntry={this.props.currentEditableEntry}
        />
      </div>
    )
  }

  _renderEvent = (event, idx, group) => {
    const date = this._getDateFromGroup(group)
    const isEmailEvent = eventUtils.isEmailEvent(event)

    if (isEmailEvent) return this.renderEmailCard(event, idx)
    return this.renderEventCard(event, idx, date)
  }

  /*
   * Render the grouped event item
   */

  _renderGroupedEvent = (events, index, group) => {
    const isEventInThreadAlreadyUsed = _.some(events, this._isAlreadyUsedEvent)
    const { conversationTopic } = _.first(events)
    const { dateTimeSent } = _.last(events)
    return (
      <GroupedEvent
        key={index}
        topic={conversationTopic}
        date={dateTimeSent}
        count={events.length}
        used={isEventInThreadAlreadyUsed}
      >
        {_.map(events, (event, idx) => this._renderEvent(event, idx, group))}
      </GroupedEvent>
    )
  }

  sortGroupedEvents = groupedEvents => {
    const eventsDate = Object.keys(groupedEvents)[0]
    const eventsArr = Object.values(groupedEvents)[0]

    const singleEvents = eventsArr.filter(event => !_.isArray(event))
    const sortedSingleEvents = eventUtils.sortEventsChronologically(singleEvents)

    const compoundEvents = eventsArr.filter(event => _.isArray(event))
    const sortedCompoundEvents = compoundEvents.map(compoundEvent =>
      eventUtils.sortEventsChronologically(compoundEvent),
    )

    const sortedEvents = [...sortedSingleEvents, ...sortedCompoundEvents]

    return { eventsDate, sortedEvents }
  }

  _getDateFromGroup = group => {
    return new Date(group.substr(group.indexOf(' ') + 1))
  }

  scrollTo(id) {
    const event = this.itemRefs[id]

    if (event && !isElementInViewport(event, this.eventListRef.current, 12)) {
      event.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }

    this.setState({ highlightedEventId: id })

    setTimeout(() => {
      this.setState({ highlightedEventId: null })
      this.props.setCurrentSelectedEvent(null, this.props.eventListIndex)
    }, 1000)
  }

  /*
   * Render the list of events
   */

  renderCurrentDayEvents = () => {
    const groupedEvents = eventUtils.getEventsByDate(this.props.events, this.props.selectedDate)
    const { eventsDate, sortedEvents } = this.sortGroupedEvents(groupedEvents)

    const events = _.map(sortedEvents, (e, i) => {
      if (_.isArray(e)) return this._renderGroupedEvent(e, i, eventsDate)
      return this._renderEvent(e, i, eventsDate)
    })

    if (this.props.isEventListLoading || this.props.areEmailsLoading) {
      return (
        <EventListLoadingContainer>
          <CircularProgress size={30} />
        </EventListLoadingContainer>
      )
    }

    if (!this.props.isEventListLoading && !this.props.areEmailsLoading && !events.length) {
      return [
        <div key="no-events-today" className="event empty">
          No events today!
        </div>,
      ]
    }

    return events
  }

  render() {
    const eventListClass = classNames({
      'event-list': true,
      submitted: this.props.isSubmitedTimesheet,
    })
    return (
      <div className={eventListClass} ref={this.eventListRef}>
        {this.renderCurrentDayEvents()}
      </div>
    )
  }
}

EventList.propTypes = {
  addToEntry: PropTypes.func.isRequired,
  createEntry: PropTypes.func.isRequired,
  usedExchangeEvents: PropTypes.object.isRequired,
  selectedDate: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired,
  isSubmitedTimesheet: PropTypes.bool.isRequired,
  entries: PropTypes.array.isRequired,
  localEntries: PropTypes.array.isRequired,
  currentEditableEntry: PropTypes.object.isRequired,
  currentSelectedEvent: PropTypes.string,
  setCurrentSelectedEvent: PropTypes.func.isRequired,
  eventListIndex: PropTypes.number.isRequired,
}

export default EventList

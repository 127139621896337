import React, { useEffect, useMemo, useState } from 'react'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import PropTypes from 'prop-types'

import moment from 'moment'
import {
  ApprovalsContainer,
  StyledPageContainer,
  StyledToggleButtonClear,
  StyledToggleContainerFullWidth,
  StyledToggleContainerPurple,
} from './Approvals.styles'
import ProjectApprovals from './ApprovalsView'
import useApprovalsData from './useApprovalsData'
import ProjectsView from './ProjecstView'
import { getProjectInvoices } from '../../entities/project/service'
import WorkInProgressPage from '../WorkInProgress/WorkInProgressPage'
import MenuSection from '../../components/MenuSection'
import ExpensesPage from '../Expenses/ExpensesPage'

const views = {
  approvals: 'approvals',
  expenses: 'expenses',
  projects: 'projects',
  wip: 'wip',
}

const useCalendarTime = ({ projects, timeEntries, rates }) => {
  const calendarValues = useMemo(() => {
    if (!projects || !timeEntries || !rates)
      return {
        current: [],
        prev: [],
      }

    const currentMonthStart = moment.utc().startOf('month')
    const currentMonthEnd = moment.utc().endOf('month')
    const prevMonthStart = currentMonthStart.clone().subtract(1, 'month')
    const prevMonthEnd = prevMonthStart.clone().endOf('month')

    const getValuesByMonth = (localEntries, localRates, monthStart, monthEnd) => {
      const currentMonthTotalDays = monthEnd.diff(monthStart, 'days') + 1

      const daysArray = Array.from({ length: currentMonthTotalDays }, (_, i) => i + 1)

      return projects.reduce((acc, project) => {
        const projectEntries = localEntries.filter(entry => entry.projectId === project._id)
        const projectRates = localRates[project._id]

        const projectValues = daysArray.map(day => {
          const currentDay = monthStart.clone().add(day - 1, 'days')

          const currentDayEntries = projectEntries.filter(entry =>
            moment(entry.entryDate).isSame(currentDay, 'day'),
          )

          const currentDayValue = currentDayEntries.reduce((sum, entry) => {
            return entry.durationInHours + sum
          }, 0)

          const currentDayRate = currentDayEntries.reduce((sum, entry) => {
            return projectRates[entry.personId] * entry.durationInHours + sum
          }, 0)

          return {
            value: currentDayValue,
            dayRate: currentDayRate,
          }
        })

        return {
          ...acc,
          [project._id]: projectValues,
        }
      }, {})
    }

    const current = getValuesByMonth(timeEntries, rates, currentMonthStart, currentMonthEnd)
    const prev = getValuesByMonth(timeEntries, rates, prevMonthStart, prevMonthEnd)

    return {
      current,
      prev,
    }
  }, [projects, timeEntries, rates])

  const calendarResume = useMemo(() => {
    const currentMonthRates = Object.entries(calendarValues.current).reduce(
      (acc, [projectId, values]) => {
        const projectTotalRate = values.reduce((sum, { dayRate }) => sum + dayRate, 0)
        const projectTotalHours = values.reduce((sum, { value }) => sum + value, 0)
        return {
          ...acc,
          [projectId]: { totalRate: projectTotalRate, totalHours: projectTotalHours },
        }
      },
      {},
    )

    const prevMonthRates = Object.entries(calendarValues.prev).reduce(
      (acc, [projectId, values]) => {
        const projectTotalRate = values.reduce((sum, { dayRate }) => sum + dayRate, 0)
        const projectTotalHours = values.reduce((sum, { value }) => sum + value, 0)
        return {
          ...acc,
          [projectId]: { totalRate: projectTotalRate, totalHours: projectTotalHours },
        }
      },
      {},
    )

    return {
      current: currentMonthRates,
      prev: prevMonthRates,
    }
  }, [calendarValues])

  return {
    calendarValues,
    calendarResume,
  }
}

const useInvoiceSummary = (client, projectId) => {
  const [invoices, setInvoices] = useState([])

  useEffect(() => {
    if (!projectId) return
    getProjectInvoices(client, projectId)
      .then(result => {
        setInvoices(result)
      })
      .catch(error => {
        console.error('Error fetching invoices:', error)
      })
  }, [client, projectId])

  const invoiceSummary = useMemo(() => {
    if (!invoices || invoices.length === 0) {
      return { latest: null, totals: [] }
    }

    // Sort invoices by due_date in descending order so that the most recent is first.
    const sortedInvoices = [...invoices].sort((a, b) => new Date(b.due_date) - new Date(a.due_date))
    const latest = sortedInvoices[0]._id

    // Map over the sorted invoices to compute the total amount for each invoice and extract the month name.
    const totals = sortedInvoices.map(invoice => {
      // Sum up the amounts from all sections of the invoice.
      const total = invoice.sections.reduce((sum, section) => sum + section.amount, 0)
      // Get the full month name from the due_date.
      const month = moment(invoice.due_date).format('MMMM YYYY')
      return { total, month }
    })

    return { latest, totals }
  }, [invoices])

  return invoiceSummary
}

const ApprovalsPage = ({ person, client }) => {
  const [view, setView] = useState(views.approvals)

  const { state, actions } = useApprovalsData({
    client,
    person,
  })

  const {
    projects,
    timesheets,
    people,
    assignments,
    availableWeeks,
    entries,
    rates,
    expenses,
  } = state
  const { calendarValues, calendarResume } = useCalendarTime({
    projects,
    timeEntries: entries,
    rates,
  })

  const {
    onEntryUpdate,
    onUpdateManyEntries,
    onWritteOff,
    onReject,
    onSplitEntry,
    onApproveExpenseBillingStatus,
  } = actions
  const hasApprovableProjects = projects?.some(project => {
    return project.isFixedBill === 'false'
  })

  useEffect(() => {
    if (hasApprovableProjects) {
      setView(views.approvals)
    } else {
      setView(views.projects)
    }
  }, [hasApprovableProjects])
  const [selectedProject, setSelectedProject] = useState(null)

  const { totals: invoiceTotals, latest: latestInvoice } = useInvoiceSummary(
    client,
    selectedProject?._id,
  )
  return (
    <StyledPageContainer>
      <MenuSection
        person={person}
        selectedTab={view}
        setSelectedTab={setView}
        latestInvoice={latestInvoice}
      />
      {[views.approvals, views.projects].includes(view) && (
        <>
          <StyledToggleContainerFullWidth>
            <ToggleButtonGroup
              value={view}
              exclusive
              style={{ margin: '0 50px' }}
              onChange={(_, selectedView) => {
                if (!selectedView) return
                setView(selectedView)
              }}
            >
              <StyledToggleContainerPurple>
                <StyledToggleButtonClear
                  value={views.approvals}
                  onClick={() => setView(views.approvals)}
                  selected={view === views.approvals}
                >
                  Week View
                </StyledToggleButtonClear>
                <StyledToggleButtonClear
                  value={views.projects}
                  selected={view === views.projects}
                  onClick={() => setView(views.projects)}
                >
                  Month View
                </StyledToggleButtonClear>
              </StyledToggleContainerPurple>
            </ToggleButtonGroup>
          </StyledToggleContainerFullWidth>
          <ApprovalsContainer>
            {view === views.approvals && (
              <ProjectApprovals
                projects={projects}
                client={client}
                onEntryUpdate={onEntryUpdate}
                onUpdateManyEntries={onUpdateManyEntries}
                onWritteOff={onWritteOff}
                onReject={onReject}
                timeEntries={entries}
                availableWeeks={availableWeeks}
                people={people}
                timesheets={timesheets}
                person={person}
                rates={rates}
                expenses={expenses}
                calendarValues={calendarValues}
                calendarResume={calendarResume}
                onSplitEntry={onSplitEntry}
                onProjectSelect={setSelectedProject}
              />
            )}
            {view === views.projects && (
              <ProjectsView
                projects={projects}
                client={client}
                people={people}
                timesheets={timesheets}
                assignments={assignments}
                calendarValues={calendarValues}
                calendarResume={calendarResume}
                onProjectSelect={setSelectedProject}
              />
            )}
          </ApprovalsContainer>
        </>
      )}

      {view === views.wip && (
        <WorkInProgressPage
          person={person}
          client={client}
          onProjectSelect={setSelectedProject}
          invoiceTotals={invoiceTotals}
          state={state}
        />
      )}

      {view === views.expenses && (
        <ExpensesPage
          projects={projects}
          client={client}
          onEntryUpdate={onEntryUpdate}
          onUpdateManyEntries={onUpdateManyEntries}
          onWritteOff={onWritteOff}
          onReject={onReject}
          expenses={expenses}
          timeEntries={entries}
          availableWeeks={availableWeeks}
          people={people}
          timesheets={timesheets}
          person={person}
          rates={rates}
          calendarValues={calendarValues}
          calendarResume={calendarResume}
          onSplitEntry={onSplitEntry}
          onProjectSelect={setSelectedProject}
          onApproveExpenseBillingStatus={onApproveExpenseBillingStatus}
        />
      )}
    </StyledPageContainer>
  )
}

ApprovalsPage.propTypes = {
  client: PropTypes.object.isRequired,

  person: PropTypes.object.isRequired,
}

export default ApprovalsPage

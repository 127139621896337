import React, { useCallback, useEffect, useState } from 'react'
import TableCell from '@material-ui/core/TableCell'
import EditIcon from '@material-ui/icons/Edit'
import DoneIcon from '@material-ui/icons/Done'
import moment from 'moment'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { StyledCommentInput } from '../Approvals.styles'
import formatCurrency from '../../../utils/formatCurrency'
import BillableDropdown from './BillableDropdown'

const StyledContent = styled.div`
  min-height: 22px;
  max-height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 2px;
`

const EditableCell = ({ value, onChange }) => {
  const [localValue, setLocalValue] = useState(value)
  const [isEditing, setIsEditing] = useState(false)

  const saveCellValue = useCallback(() => {
    setIsEditing(false)
    onChange(localValue)
  }, [onChange, localValue, isEditing])

  const listenEnter = useCallback(
    event => {
      if (event.key === 'Enter') {
        saveCellValue()
      }
    },
    [saveCellValue],
  )

  const listenEscape = useCallback(event => {
    if (event.key === 'Escape') {
      setIsEditing(false)
    }
  }, [])

  useEffect(() => {
    if (isEditing) {
      window.addEventListener('keydown', listenEnter)
      window.addEventListener('keydown', listenEscape)
    } else {
      window.removeEventListener('keydown', listenEnter)
      window.removeEventListener('keydown', listenEscape)
    }
    return () => window.removeEventListener('keydown', listenEnter)
  }, [isEditing, listenEnter, listenEscape])

  if (!isEditing) {
    return (
      <TableCell>
        <StyledContent>
          <p
            style={{
              margin: '0px',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
              maxWidth: '300px',
              minWidth: '100px',
              width: '300px',
            }}
            title={value}
          >
            {value}
          </p>
          <EditIcon onClick={() => setIsEditing(true)} />
        </StyledContent>
      </TableCell>
    )
  }

  return (
    <TableCell>
      <StyledContent>
        <StyledCommentInput
          type="text"
          value={localValue}
          onChange={e => setLocalValue(e.target.value)}
        />
        <DoneIcon onClick={() => saveCellValue()} />
      </StyledContent>
    </TableCell>
  )
}

const EntryColumns = ({ onUpdateEntry, entry, rates = {}, project }) => {
  const { durationInHours } = entry
  const rate = rates[entry?.personId] || 0
  return (
    <>
      <TableCell>{moment.utc(entry.entryDate).format('MM/DD/YYYY')}</TableCell>
      <EditableCell
        value={entry.comment}
        onChange={val => onUpdateEntry([{ ...entry, comment: val }])}
      />
      <TableCell>{entry.durationInHours.toFixed(2)}</TableCell>
      <TableCell>
        <BillableDropdown
          entry={entry}
          onChange={val => onUpdateEntry([{ ...entry, billable: val }])}
        />
      </TableCell>
      <TableCell>{formatCurrency(rate, project.CurrencyIsoCode)}</TableCell>
      <TableCell>{formatCurrency(rate * durationInHours, project.CurrencyIsoCode)}</TableCell>
    </>
  )
}

EntryColumns.propTypes = {
  entry: PropTypes.shape({
    comment: PropTypes.string,
    durationInHours: PropTypes.number,
    startDate: PropTypes.string,
    billable: PropTypes.string,
  }).isRequired,
  onUpdateEntry: PropTypes.func.isRequired,
  rates: PropTypes.object,
}

export default EntryColumns

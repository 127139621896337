import styled, { css } from 'styled-components'
import { withStyles } from '@material-ui/core/styles'

import blue from '@material-ui/core/colors/blue'

import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'
import { Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'

export const Container = styled(Box)`
  display: grid;
  padding: 12px;
  grid-template-columns: 2fr 6fr 4fr;
  grid-template-rows: auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-areas:
    'profile notifications menu'
    'period calendar views'
    'timer totals submit';
  z-index: 250;
  background-color: #fff;
  @media (min-width: 768px) {
    grid-template-columns: 2fr 6fr 4fr;
  }
`
export const ContainerCol = styled(Box)`
  display: flex;
  flex-direction: column;
`
export const ContainerRow = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 3px;
`

export const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  color: ${blue[500]};
  height: 20px;
  width: 20px;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);

  &:hover {
    cursor: pointer;

    & svg {
      filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.3));
    }
  }
`

export const ArrowLeft = styled(Arrow)`
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
`

export const ArrowRight = styled(Arrow)`
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
`

export const DayPickerArrow = styled(Arrow)`
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
  border: none;
  box-shadow: none;
`

export const CalendarHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px 10px 0 10px;
  position: relative;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  min-height: ${props => (props.daily ? 137 : 145)}px;

  > div {
    z-index: 100;
    -webkit-app-region: no-drag;
  }

  > div:nth-child(1) {
    display: flex;
    align-items: center;
    height: 25px;
    width: 100%;
    -webkit-app-region: drag;
  }

  > div:nth-child(2) {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
  }

  > div:nth-child(3) {
    display: flex;
    align-items: center;
    width: 100%;
    height: 38px;
  }
`

export const ContentLarge = styled.div`
  width: calc(100% - 120px);
`

export const ContentSmall = styled.div`
  width: 120px;
`

export const MenuContainer = styled.div`
  grid-area: menu;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const TimesheetTotals = styled.div`
  grid-area: totals;
  display: flex;
  align-items: center;

  @media (max-width: 495px) {
    justify-content: center;
    padding-left: 0;
  }
`

export const SubmissionToolbarContainer = styled.div`
  grid-area: submit;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const styles = () => ({
  bootstrapRoot: {
    textTransform: 'none',
    fontSize: '1rem',
    padding: '1px 30px',
    backgroundColor: blue[500],
    borderColor: blue[500],
    height: '28px',
    width: '120px',
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -10,
  },
})

export const TimesheetTooltip = withStyles(() => ({
  tooltip: {
    fontSize: '14px',
  },
}))(Tooltip)

export const ApprovalsBtn = styled(Link)(({ theme: { colors } } = {}) => {
  return css`
    text-transform: none;
    font-size: 1rem;
    padding: 1px 30px;
    background-color: ${colors.nightshade.light};
    height: 28px;
    min-width: 150px;
    color: ${colors.gray[100]};
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 1rem;

    justify-content: center;

    &:hover {
      text-decoration: none;

      background-color: ${colors.nightshade.medium};
    }
  `
})

export const WeekTabButton = styled(Button)(function({
  minWidth,
  selected,
  theme: { colors },
} = {}) {
  return css`
    text-transform: none;
    font-size: 1rem;
    padding: 1px 30px;
    background-color: ${colors.paper};
    height: 28px;
    min-width: 150px;
    color: ${colors.gray[900]};
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 2px solid ${colors.gray[100]};
    justify-content: center;

    &:hover {
      text-decoration: none;
      color: ${colors.nightshade.medium};
      background-color: ${colors.paper};
    }

    &:focus {
      color: ${colors.nightshade.medium};
      background-color: ${colors.paper};
    }

    ${selected &&
      css`
        color: ${colors.nightshade.medium};
        background-color: ${colors.paper};
        font-weight: 700;
      `}
    height: 100%;
    border-radius: 0;
    ${minWidth && `min-width: ${minWidth};`}
  `
})

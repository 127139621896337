import _ from 'lodash'
import {
  GET_TIME_ENTRIES,
  UPDATE_TIME_ENTRY,
  DELETE_TIME_ENTRY,
  CREATE_TIME_ENTRY,
  CREATE_TIME_ENTRY_AS_DELEGATE,
  DELETE_TIME_ENTRY_AS_DELEGATE,
  GET_TIME_ENTRIES_AS_DELEGATE,
  UPDATE_TIME_ENTRY_AS_DELEGATE,
  GET_TIME_ENTRIES_BY_PROJECT,
  SPLIT_TIME_ENTRY,
} from './query'
import { getUpdatedTimeEntryEvent } from '../../../server/actions/helpers/timeEntryActionHelper'
import TimeEntry from '../../../server/models/timeEntry'

import { addEventDataToEntry, addEventDataToEntries } from '../../../server/funnel/funnelUtils'

import { convertDateToLocalTimezone } from '../../../server/utils/date'

import { logError, log } from '../../../utils/logger'
import { getDiff } from '../../../utils/diff'

export const fetchTimeEntriesInDateRange = async ({
  client,
  startISO,
  endISO,
  selectedDelegateId,
}) => {
  const { data } = await client.query({
    query: selectedDelegateId ? GET_TIME_ENTRIES_AS_DELEGATE : GET_TIME_ENTRIES,
    variables: {
      startISO,
      endISO,
      delegateId: selectedDelegateId,
    },
  })
  return selectedDelegateId ? data.getTimeEntries_asDelegate : data.getTimeEntries
}

export const updateTimeEntry = async ({ client, timeEntry, selectedDelegateId }) => {
  const updatedTimeEntryEvent = getUpdatedTimeEntryEvent(timeEntry)

  const { data } = await client.query({
    query: selectedDelegateId ? UPDATE_TIME_ENTRY_AS_DELEGATE : UPDATE_TIME_ENTRY,
    variables: {
      delegateId: selectedDelegateId,
      ...updatedTimeEntryEvent,
    },
  })

  const updatedTimeEntry = selectedDelegateId
    ? data.updateTimeEntry_asDelegate
    : data.updateTimeEntry

  const updatedEntryToSave = addEventDataToEntry(updatedTimeEntry)

  await TimeEntry.where('_id')
    .equals(updatedEntryToSave._id)
    .modify(updatedEntryToSave)
  return updatedEntryToSave
}

export const deleteTimeEntry = async ({ client, timeEntry, selectedDelegateId }) => {
  await client.mutate({
    mutation: selectedDelegateId ? DELETE_TIME_ENTRY_AS_DELEGATE : DELETE_TIME_ENTRY,
    variables: {
      _id: timeEntry._id,
      delegateId: selectedDelegateId,
    },
  })
  await TimeEntry.delete(timeEntry._id)
}

export const createTimeEntry = async ({ client, timeEntry, selectedDelegateId }) => {
  const { data } = await client.mutate({
    mutation: selectedDelegateId ? CREATE_TIME_ENTRY_AS_DELEGATE : CREATE_TIME_ENTRY,
    variables: {
      delegateId: selectedDelegateId,
      billable: timeEntry.billable,
      end: timeEntry.end,
      originalComment: timeEntry.originalComment,
      originalDurationInHours: timeEntry.originalDurationInHours,
      originalEntryDate: timeEntry.originalEntryDate,
      originalProjectId: timeEntry.originalProjectId,
      referenceId: timeEntry.referenceId,
      referenceType: timeEntry.referenceType,
      repliconTimesheetId: timeEntry.repliconTimesheetId,
      sourceId: timeEntry.sourceId,
      start: timeEntry.start,
      creationWay: timeEntry.creationWay,
      eventTagId: timeEntry.eventTagId,
    },
  })
  return selectedDelegateId ? data.createTimeEntry_asDelegate : data.createTimeEntry
}

export const assureTimeEntriesExistLocally = async ({
  client,
  startISO,
  endISO,
  selectedDelegateId,
}) => {
  try {
    const localStartISO = convertDateToLocalTimezone(startISO)
    const localEndISO = convertDateToLocalTimezone(endISO)

    const funnelTimeEntries = await fetchTimeEntriesInDateRange({
      client,
      startISO,
      endISO,
      selectedDelegateId,
    })

    const localTimeEntries = await TimeEntry.where('originalEntryDate')
      .between(localStartISO, localEndISO, true, true)
      .toArray()

    const adoptedFunnelEntries = addEventDataToEntries(funnelTimeEntries)
    const { create, update, remove } = getDiff({
      oldData: localTimeEntries,
      newData: adoptedFunnelEntries,
      uniqueId: '_id',
    })
    log(`ES -> LS -> TimeEntry → C: ${_.size(create)} U: ${_.size(update)} D: ${_.size(remove)}`)
    await TimeEntry.bulkDelete(remove.map(e => e._id))
    await TimeEntry.bulkPut(update)
    await TimeEntry.bulkAdd(create)
  } catch (e) {
    logError(e, 'entities/timeEntry/service/index > assureTimeEntriesExistLocally > e:')
  }
}

export const getTimeEntriesByProject = async ({
  projectId,
  client,
  repliconTimesheetId,
  approveState,
}) => {
  const { data } = await client.query({
    query: GET_TIME_ENTRIES_BY_PROJECT,
    variables: {
      projectIds: projectId,
      repliconTimesheetId,
      approveState,
    },
  })

  return data.getTimeEntriesByProjectIds
}

export const splitTimeEntry = async ({ client, timeEntryId, durationInHours }) => {
  const { data } = await client.mutate({
    mutation: SPLIT_TIME_ENTRY,
    variables: {
      entryId: timeEntryId,
      durationInHours,
    },
  })

  return data.splitTimeEntry
}

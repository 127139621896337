import React from 'react'
import PropTypes from 'prop-types'

import { StyledPageContainer } from '../Approvals/Approvals.styles'
import { ExpensesContainer } from './Expenses.styles'
import ExpensesView from './ExpensesView'

const ExpensesPage = ({
  projects,
  client,
  onEntryUpdate,
  onUpdateManyEntries,
  expenses,
  timeEntries,
  timesheets,
  people,
  person,
  availableWeeks,
  onWritteOff,
  onReject,
  rates,
  calendarValues,
  calendarResume,
  onSplitEntry,
  onProjectSelect,
  onApproveExpenseBillingStatus,
}) => {
  return (
    <StyledPageContainer>
      <ExpensesContainer>
        <ExpensesView
          projects={projects}
          client={client}
          onEntryUpdate={onEntryUpdate}
          onUpdateManyEntries={onUpdateManyEntries}
          onWritteOff={onWritteOff}
          onReject={onReject}
          expenses={expenses}
          timeEntries={timeEntries}
          availableWeeks={availableWeeks}
          people={people}
          timesheets={timesheets}
          person={person}
          rates={rates}
          calendarValues={calendarValues}
          calendarResume={calendarResume}
          onSplitEntry={onSplitEntry}
          onProjectSelect={onProjectSelect}
          onApproveExpenseBillingStatus={onApproveExpenseBillingStatus}
        />
      </ExpensesContainer>
    </StyledPageContainer>
  )
}

ExpensesPage.propTypes = {
  client: PropTypes.object.isRequired,

  person: PropTypes.object.isRequired,
}

export default ExpensesPage

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'
import { withApollo } from '@apollo/client/react/hoc'
import { withAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router'
import { mapStateToProps, mapDispatchToProps } from './store'
import { AuthPageContainer, WelcomeText, DraggableContainer } from '../styles'
import { useAuthRedirect } from './utils'

import { AuthPageNavigation, ButtonContainer, useStyles } from './styles'

const Auth = ({
  isAuthInProgress,
  isUserLoggedIn,
  hasUserGotStarted,
  isInitialSyncInProgress,
  login,
  client,
  auth0,
}) => {
  const history = useHistory()
  const styles = useStyles()
  useAuthRedirect({ isUserLoggedIn, hasUserGotStarted, isInitialSyncInProgress })
  const [token, setToken] = React.useState('')

  useEffect(() => {
    auth0
      .getAccessTokenSilently()
      .then(cachedToken => {
        setToken(cachedToken)
      })
      .catch(() => {
        setToken(null)
      })
  }, [])

  if (token && !isUserLoggedIn) {
    login(auth0, client, history, true)
    return null
  }

  return (
    <DraggableContainer>
      <AuthPageContainer>
        <WelcomeText>
          <div>
            <h1 id="welcome-text">Welcome to Glimpse </h1>
          </div>
          <div>
            <h2 id="status">Login to continue</h2>
          </div>
        </WelcomeText>
        <AuthPageNavigation>
          <ButtonContainer>
            <Button
              data-testid="auth.loginButton"
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              className={styles.button}
              disabled={isAuthInProgress || auth0.isLoading}
              onClick={() => login(auth0, client, history)}
            >
              Login with Outlook 365
            </Button>
            {(isAuthInProgress || auth0.isLoading) && (
              <CircularProgress size={24} className={styles.buttonProgress} />
            )}
          </ButtonContainer>
        </AuthPageNavigation>
        <div style={{ padding: '10px 0px' }}>
          {auth0.error && <Alert severity="error">{`${auth0.error.message}`}</Alert>}
        </div>
      </AuthPageContainer>
    </DraggableContainer>
  )
}

Auth.propTypes = {
  login: PropTypes.func.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  isAuthInProgress: PropTypes.bool.isRequired,
  isInitialSyncInProgress: PropTypes.bool.isRequired,
  hasUserGotStarted: PropTypes.bool.isRequired,
  auth0: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
}

export default withApollo(connect(mapStateToProps, mapDispatchToProps)(withAuth0(Auth)))

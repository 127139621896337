import config from '../../../config'

export const getGlimpseApiTokenMutation = async (auth0Client, silent, multiLogin) => {
  let glimpseApiToken
  try {
    if (multiLogin) {
      glimpseApiToken = await auth0Client.getAccessTokenSilently()
    } else if (silent)
      glimpseApiToken = await auth0Client.getAccessTokenSilently({
        audience: config.AUTH0_GLIMPSE_AUDIENCE,
        connection_scope: 'offline_access',
      })
    else
      glimpseApiToken = await auth0Client.getAccessTokenWithPopup({
        audience: config.AUTH0_GLIMPSE_AUDIENCE,
        connection_scope: 'offline_access',
      })
  } catch (error) {
    glimpseApiToken = await auth0Client.getAccessTokenWithPopup({
      audience: config.AUTH0_GLIMPSE_AUDIENCE,
      connection_scope: 'offline_access',
    })
  }

  if (typeof glimpseApiToken === 'undefined') {
    return null
  }

  return glimpseApiToken
}

import { useEffect, useState } from 'react'
import { getWipReportsByProjectIds } from '../../entities/project/service'
import moment from 'moment'

const useWipData = ({ client, projects = [], selectedProject }) => {
  const [wipReport, setWipReport] = useState()

  useEffect(() => {
    const fetchWipReports = async () => {
      const projectIds = projects.map(project => project._id).join(',')
      const months = [moment().format('YYYY-MM')].join(',')

      const result = await getWipReportsByProjectIds(client, projectIds, months)

      const report = result.find(report => report.projectId === selectedProject._id)
        ?.monthlyWipReports?.[0]

      setWipReport(report)
    }

    fetchWipReports()
  }, [client, projects, selectedProject])

  return wipReport
}

export default useWipData

import { CREATE_ANALYTICS_INITIAL_SYNC_TIME_TRACK } from './query'

export const analytics_initialSyncTime_track = async ({
  client,
  timesheetDataLoadTime,
}) => {
  const { data } = await client.mutate({
    mutation: CREATE_ANALYTICS_INITIAL_SYNC_TIME_TRACK,
    variables: {
      timesheetDataLoadTime,
    },
  })
  return data.analytics_initialSyncTime_track
}

import React, { Component } from 'react'
import { Tab, Tabs } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpOutlined from '@material-ui/icons/ArrowDropUpOutlined'
import PropTypes from 'prop-types'
import { EventListArrowIconWrapper, EventListButton, styles } from './style'
import EventList from './EventList'
import AddAllEventsButton from '../AddAllEventsButton'

class EventListContainer extends Component {
  handleTabChange = (event, tabIndex) => {
    this.props.setEventListIndex(tabIndex)
  }

  getEmailsTabLength = () => {
    return this.props.emails.length
  }

  getEventsTabLength = () => {
    return this.props.calendarEvents.length
  }

  getDisplayedEvents = () => {
    return this.props.eventListIndex === 0 ? this.props.calendarEvents : this.props.emails
  }

  renderArrowIcon = () => {
    return (
      <EventListArrowIconWrapper>
        {this.props.isEventsShown ? this.renderArrowDownIcon() : this.renderArrowUpIcon()}
      </EventListArrowIconWrapper>
    )
  }

  renderArrowDownIcon = () => (
    <ArrowDropDown onClick={this.props.hideEvents} className="events-arrow arrow-down" />
  )

  renderArrowUpIcon = () => (
    <ArrowDropUpOutlined onClick={this.props.showEvents} className="events-arrow arrow-up" />
  )

  renderEventList() {
    const events = this.getDisplayedEvents()

    return (
      <EventList
        events={events}
        entries={this.props.entries}
        localEntries={this.props.localEntries}
        isSubmitedTimesheet={this.props.isSubmitedTimesheet}
        selectedDate={this.props.selectedDate}
        addToEntry={this.props.addToEntry}
        createEntry={this.props.createEntry}
        usedExchangeEvents={this.props.usedExchangeEvents}
        currentEditableEntry={this.props.currentEditableEntry}
        currentSelectedEvent={this.props.currentSelectedEvent}
        setCurrentSelectedEvent={this.props.setCurrentSelectedEvent}
        eventListIndex={this.props.eventListIndex}
        isEventListLoading={this.props.isEventListLoading}
        areEmailsLoading={this.props.areEmailsLoading}
      />
    )
  }

  render() {
    const { classes } = this.props
    return (
      <div className="event-list-container">
        <AppBar
          position="relative"
          color="default"
          className="tabs-wrapper"
          classes={{ root: classes.AppBarRoot }}
        >
          <EventListButton>
            <AddAllEventsButton
              isConnected={this.props.isConnected}
              handleCreateEntriesForAllEvents={this.props.handleCreateEntriesForAllEvents}
              timeEntries={this.props.timeEntries}
              localEntries={this.props.localEntries}
              exchangeEvents={this.props.exchangeEvents}
              isSubmitInProgress={this.props.isSubmitInProgress}
              isFetchingTimesheetData={this.props.isFetchingTimesheetData}
              uiRefreshTimePeriod={this.props.uiRefreshTimePeriod}
              isTimesheetSubmitted={this.props.isTimesheetSubmitted}
            />
          </EventListButton>
          <Tabs
            variant="fullWidth"
            value={this.props.eventListIndex}
            onChange={this.handleTabChange}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
              flexContainer: classes.flexContainer,
            }}
          >
            <Tab
              tabIndex="-1"
              wrapped
              label={`${this.getEventsTabLength()} Events`}
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            />
            <Tab
              tabIndex="-1"
              label={`${this.getEmailsTabLength()} Emails`}
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            />
          </Tabs>
          {!this.props.hideArrowIcon ? this.renderArrowIcon() : null}
        </AppBar>
        {this.renderEventList()}
      </div>
    )
  }
}

EventListContainer.propTypes = {
  hideEvents: PropTypes.func,
  showEvents: PropTypes.func,
  isEventsShown: PropTypes.bool,
  calendarEvents: PropTypes.array.isRequired,
  emails: PropTypes.array.isRequired,
  addToEntry: PropTypes.func.isRequired,
  createEntry: PropTypes.func.isRequired,
  selectedDate: PropTypes.object.isRequired,
  usedExchangeEvents: PropTypes.object.isRequired,
  isSubmitedTimesheet: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  entries: PropTypes.array.isRequired,
  isConnected: PropTypes.bool.isRequired,
  handleCreateEntriesForAllEvents: PropTypes.func.isRequired,
  timeEntries: PropTypes.array.isRequired,
  localEntries: PropTypes.array.isRequired,
  exchangeEvents: PropTypes.array.isRequired,
  isSubmitInProgress: PropTypes.bool.isRequired,
  isFetchingTimesheetData: PropTypes.bool.isRequired,
  uiRefreshTimePeriod: PropTypes.shape({
    startISO: PropTypes.string.isRequired,
    endISO: PropTypes.string.isRequired,
  }).isRequired,
  currentEditableEntry: PropTypes.object.isRequired,
  isTimesheetSubmitted: PropTypes.bool.isRequired,
  hideArrowIcon: PropTypes.bool,
  currentSelectedEvent: PropTypes.string,
  setCurrentSelectedEvent: PropTypes.func.isRequired,
  eventListIndex: PropTypes.number.isRequired,
  setEventListIndex: PropTypes.func.isRequired,
}

EventListContainer.defaultProps = {
  hideArrowIcon: false,
  isEventsShown: false,
  hideEvents: () => {},
  showEvents: () => {},
}

export default withStyles(styles)(EventListContainer)

import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import moment from 'moment/moment'
import CalendarDateSelector from '../Header/CalendarDateSelector'
import { GeneralButton, StyledControlsContainer } from '../style'
import { ksTheme } from '../../../theme'
import {
  getMissingNavigationStartDate,
  searchForNextClosestMissingTimesheetMonth,
  searchForPreviousClosestMissingTimesheetMonth,
} from '../../../utils/timesheetUtils'
import TimeEntry from '../../../server/models/timeEntry'
import { TIMESHEET_VIEWS } from '../../../constants/glimpseViews'
import { getNumberOfMissing } from '../Header/UserProfile/utils'

const SidebarControls = ({
  isDayViewPeriodSelected,
  onNavigate,
  selectedDate,
  isTimesheetLoading,
  setViewPeriodDay,
  setViewPeriodWeek,
  view,
  timesheets,
  events,
  person,
  handleTogglePageView,
  userSettings,
  selectedDelegateId,
  delegateAccessList,
  handleCalendarSelect,
}) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false)
  const [currentMonth, setCurrentMonth] = React.useState(new Date())
  const [rejectedEntries, setRejectedEntries] = React.useState([])
  const [pendingEntries, setPendingEntries] = React.useState([])

  const changeDate = newDate => {
    handleCalendarSelect(newDate)
    setCurrentMonth(newDate)
    handleCloseDayPicker()
  }

  useEffect(() => {
    TimeEntry.toArray().then(entries => {
      const rejected = entries.filter(entry => entry.approveState?.status === 'rejected')
      const pending = entries.filter(entry => entry.approveState?.status === 'pending')
      setRejectedEntries(rejected)
      setPendingEntries(pending)
    })
  }, [])

  const handleDateRangeClick = () => {
    setIsDatePickerOpen(false)
    setCurrentMonth(selectedDate)
  }

  const handleCloseDayPicker = () => {
    setIsDatePickerOpen(false)
  }

  const navigateToPreviousMissingTimesheet = () => {
    const newCurrentMonth = searchForPreviousClosestMissingTimesheetMonth({
      missingNavigationStartDate: getMissingNavigationStartDate(person.startDate),
      searchingDate: moment(currentMonth)
        .subtract(1, 'months')
        .startOf('month'),
      timesheets,
    })

    setCurrentMonth(newCurrentMonth)
  }

  const navigateToNextMissingTimesheet = () => {
    const newCurrentMonth = searchForNextClosestMissingTimesheetMonth({
      missingNavigationStartDate: getMissingNavigationStartDate(person.startDate),
      searchingDate: moment(currentMonth)
        .add(1, 'months')
        .startOf('month'),
      timesheets,
    })

    setCurrentMonth(newCurrentMonth)
  }

  const navigateToEarliestMissingTimesheet = () => {
    const missingNavigationStartDate = getMissingNavigationStartDate(person.startDate)
    const newCurrentMonth = searchForNextClosestMissingTimesheetMonth({
      missingNavigationStartDate,
      searchingDate: missingNavigationStartDate,
      timesheets,
    })
    setCurrentMonth(newCurrentMonth)
  }

  const numberOfMissingTimesheets = getNumberOfMissing({ timesheets, startDate: person.startDate })

  return (
    <StyledControlsContainer>
      <CalendarDateSelector
        isDayViewPeriodSelected={isDayViewPeriodSelected}
        changeDate={changeDate}
        onNavigate={onNavigate}
        selectedDate={selectedDate}
        handleDateRangeClick={handleDateRangeClick}
        handleCloseDayPicker={handleCloseDayPicker}
        isDatePickerOpen={isDatePickerOpen}
        isTimesheetLoading={isTimesheetLoading}
        setViewPeriodDay={setViewPeriodDay}
        setViewPeriodWeek={setViewPeriodWeek}
        view={view}
        timesheets={timesheets}
        events={events}
        userInfo={person}
        currentMonth={currentMonth}
        updateCurrentMonth={setCurrentMonth}
        navigateToPreviousMissingTimesheet={navigateToPreviousMissingTimesheet}
        navigateToNextMissingTimesheet={navigateToNextMissingTimesheet}
        navigateToEarlistMissingTimesheet={navigateToEarliestMissingTimesheet}
        handleTogglePageView={handleTogglePageView}
        userSettings={userSettings}
        selectedDelegateId={selectedDelegateId}
        delegateAccessList={delegateAccessList}
        pendingEntries={pendingEntries}
      />
      {view === TIMESHEET_VIEWS.REJECTED_ENTRIES && (
        <GeneralButton
          hoverBgColor={ksTheme.colors.nightshade.medium}
          bgColor={ksTheme.colors.nightshade.dark}
          onClick={() => handleTogglePageView({}, TIMESHEET_VIEWS.TIMELINE)}
        >
          Today Timesheets
        </GeneralButton>
      )}

      <GeneralButton
        onClick={navigateToEarliestMissingTimesheet}
        hoverBgColor={ksTheme.colors.gray['900']}
        bgColor={ksTheme.colors.gray['700']}
      >
        {numberOfMissingTimesheets} Missing Timesheets
      </GeneralButton>
      <GeneralButton
        onClick={() => handleTogglePageView({}, TIMESHEET_VIEWS.REJECTED_ENTRIES)}
        hoverBgColor={ksTheme.colors.gray['900']}
        bgColor={ksTheme.colors.gray['700']}
      >
        {rejectedEntries.length} Rejected Time entries
      </GeneralButton>
      <GeneralButton
        hoverBgColor={ksTheme.colors.darkGreen.light}
        bgColor={ksTheme.colors.darkGreen.light}
        clickable={false}
      >
        {pendingEntries.length} Entries Awaiting approvals
      </GeneralButton>
    </StyledControlsContainer>
  )
}

SidebarControls.propTypes = {
  isDayViewPeriodSelected: PropTypes.bool.isRequired,
  onNavigate: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  isTimesheetLoading: PropTypes.bool.isRequired,
  setViewPeriodDay: PropTypes.func.isRequired,
  setViewPeriodWeek: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  timesheets: PropTypes.arrayOf(PropTypes.object).isRequired,
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  person: PropTypes.object.isRequired,
  handleTogglePageView: PropTypes.func.isRequired,
  userSettings: PropTypes.object.isRequired,
  selectedDelegateId: PropTypes.string,
  delegateAccessList: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleCalendarSelect: PropTypes.func.isRequired,
}

export default SidebarControls

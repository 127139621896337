import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import UserProfile from '../../views/Calendar/Header/UserProfile'
import NotificationBar from '../../views/Calendar/Header/NotificationBar'
import DelegateAccess from '../../views/Calendar/Header/DelegateAccess'
import { ApprovalsBtn, ContainerRow } from '../../views/Calendar/Header/styles'
import GlimpseMenu from '../../views/Calendar/Header/GlimpseMenu'
import config from '../../config'
import { mapStateToProps } from '../../views/Calendar/Header/store'

const { IS_DELEGATE_ENABLED } = config

const AppNavigation = ({
  person,
  timesheets,
  hasTimesheetUnassignedEntries,
  hasWeekTimesheetsUnassignedEntries,
  isDayViewPeriodSelected,
  uiRefreshTimePeriod,
  localEntries,
  isConnected,
  delegateAccessList,
  selectedDelegateId,
  handleSelectedDelegateId,
  handleReloadApp,
  handleUpload,
  handleSwitchIsIssueReportModalOpen,
  handleSwitchIsDelegateAccessModalOpen,
  handleCreateEntriesForAllEvents,
  timeEntries,
  exchangeEvents,
  isSubmitInProgress,
  isFetchingTimesheetData,
  reminderSettings,
  handleSetReminderSettings,
  isTimerDisabled,
  assignments,
}) => {
  const isLeadingProjects = assignments?.some(assignment => assignment.type !== 0)
  return (
    <ContainerRow style={{ justifyContent: 'space-between', width: '100%' }}>
      <UserProfile person={person} timesheets={timesheets} />
      <NotificationBar
        hasTimesheetUnassignedEntries={hasTimesheetUnassignedEntries}
        hasWeekTimesheetsUnassignedEntries={hasWeekTimesheetsUnassignedEntries}
        isDayViewPeriodSelected={isDayViewPeriodSelected}
        uiRefreshTimePeriod={uiRefreshTimePeriod}
        localEntries={localEntries}
        isConnected={isConnected}
      />
      {IS_DELEGATE_ENABLED && (
        <DelegateAccess
          delegateAccessList={delegateAccessList}
          selectedDelegateId={selectedDelegateId}
          handleSelectedDelegateId={handleSelectedDelegateId}
        />
      )}
      <ContainerRow style={{ gap: 2 }}>
        {isLeadingProjects && (
          <ApprovalsBtn href="#/lead-view/approvals">Projects Dashboard</ApprovalsBtn>
        )}
        <GlimpseMenu
          handleReloadApp={handleReloadApp}
          handleUpload={handleUpload}
          handleSwitchIsIssueReportModalOpen={handleSwitchIsIssueReportModalOpen}
          handleSwitchIsDelegateAccessModalOpen={handleSwitchIsDelegateAccessModalOpen}
          handleCreateEntriesForAllEvents={handleCreateEntriesForAllEvents}
          timeEntries={timeEntries}
          localEntries={localEntries}
          exchangeEvents={exchangeEvents}
          isSubmitInProgress={isSubmitInProgress}
          isFetchingTimesheetData={isFetchingTimesheetData}
          uiRefreshTimePeriod={uiRefreshTimePeriod}
          isConnected={isConnected}
          reminderSettings={reminderSettings}
          handleSetReminderSettings={handleSetReminderSettings}
          isTimesheetSubmitted={isTimerDisabled}
          person={person}
        />
      </ContainerRow>
    </ContainerRow>
  )
}

AppNavigation.propTypes = {
  person: PropTypes.object.isRequired,
  timesheets: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasTimesheetUnassignedEntries: PropTypes.bool.isRequired,
  hasWeekTimesheetsUnassignedEntries: PropTypes.bool.isRequired,
  isDayViewPeriodSelected: PropTypes.bool.isRequired,
  uiRefreshTimePeriod: PropTypes.shape({
    startISO: PropTypes.string.isRequired,
    endISO: PropTypes.string.isRequired,
  }).isRequired,
  localEntries: PropTypes.arrayOf(PropTypes.object).isRequired,
  isConnected: PropTypes.bool.isRequired,
  delegateAccessList: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedDelegateId: PropTypes.string,
  handleSelectedDelegateId: PropTypes.func.isRequired,
  handleReloadApp: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  handleSwitchIsIssueReportModalOpen: PropTypes.func.isRequired,
  handleSwitchIsDelegateAccessModalOpen: PropTypes.func.isRequired,
  handleCreateEntriesForAllEvents: PropTypes.func.isRequired,
  timeEntries: PropTypes.arrayOf(PropTypes.object).isRequired,
  exchangeEvents: PropTypes.arrayOf(PropTypes.object).isRequired,
  isSubmitInProgress: PropTypes.bool.isRequired,
  isFetchingTimesheetData: PropTypes.bool.isRequired,
  reminderSettings: PropTypes.shape({
    shouldRemind: PropTypes.bool.isRequired,
    weekDays: PropTypes.shape({
      sunday: PropTypes.bool.isRequired,
      monday: PropTypes.bool.isRequired,
      tuesday: PropTypes.bool.isRequired,
      wednesday: PropTypes.bool.isRequired,
      thursday: PropTypes.bool.isRequired,
      friday: PropTypes.bool.isRequired,
      saturday: PropTypes.bool.isRequired,
    }).isRequired,
    timeFrom: PropTypes.instanceOf(Date).isRequired,
    timeTo: PropTypes.instanceOf(Date).isRequired,
    periodInMinutes: PropTypes.number.isRequired,
  }).isRequired,
  handleSetReminderSettings: PropTypes.func.isRequired,
  isTimerDisabled: PropTypes.bool.isRequired,
  assignments: PropTypes.array,
}

export default connect(mapStateToProps)(AppNavigation)

import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles({
  headerCell: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
})

const HistoricalInvoiceAmountsTable = ({ invoiceTotals }) => {
  const classes = useStyles()

  return (
    <TableContainer component={Paper}>
      <Table aria-label="WIP Report Table">
        <TableHead>
          <TableRow>
            <TableCell />
            {invoiceTotals.map(invoiceAmount => (
              <TableCell className={classes.headerCell}>{invoiceAmount.month}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Invoice Amount</TableCell>
            {invoiceTotals.map(invoiceAmount => (
              <TableCell>{invoiceAmount.total}</TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default HistoricalInvoiceAmountsTable

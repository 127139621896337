import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ShareIcon from '@material-ui/icons/Share'
import IconButton from '@material-ui/core/IconButton/IconButton'
import moment from 'moment'
import ClearIcon from '@material-ui/icons/Clear'
import Typography from '@material-ui/core/Typography'
import { WorkInProgressViewContainer } from './WorkInProgressView.styles'
import ProjectSelection from '../../components/ProjectSelection'
import WipReportTable from './Components/WipReportTable'
import KeyDatesTable from './Components/KeyDatesTable'
import HistoricalInvoiceAmountsTable from './Components/HistoricalInvoiceAmountsTable'
import SummaryReport from './Components/SummaryReport'
import WipReportPreviewDropdown from './Components/WipReportPreviewDropdown'
import DetailedReport from './Components/DetailedReport'
import useWipData from './useWipData'

const WorkInProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

const InformativeAdvice = () => (
  <p style={{ width: '80%', maxWidth: '1000px' }}>
    Overall the weekly precisions is valuable when framed within the context of the overall
    budget/project.
    <br />
    <br />
    For that we will want to get into SF the basic project milestones: (1) first drafts of opening
    repots (2) filing of opening reports (3) rebuttal reports (4) depositions (5) trial
  </p>
)

document.body.style.overflow = 'auto'

const getCurrentMonthName = () => {
  const date = moment()
  return date.format('MMMM YYYY')
}

const WorkInProgressView = ({ client, projects, person, invoiceTotals }) => {
  const [selectedProject, setSelectedProject] = useState(projects[0])
  const [preview, setPreview] = useState()
  useEffect(() => {
    setSelectedProject(projects[0])
  }, [projects])
  const month = useMemo(() => getCurrentMonthName(), [])
  const setProject = id => {
    setSelectedProject(projects.find(project => project._id === id))
  }

  const wipReport = useWipData({ client, projects, selectedProject })

  return wipReport ? (
    <WorkInProgressContainer>
      {!preview && (
        <WorkInProgressViewContainer>
          {selectedProject && (
            <ProjectSelection
              projects={projects}
              selectedProject={selectedProject?._id}
              setSelectedProject={setProject}
            />
          )}

          <div style={{ width: '100%' }}>
            <h2 style={{ textAlign: 'center' }}>Work in Progress Report - Current Month to Date: {month}</h2>
            <WipReportTable project={selectedProject} wipData={wipReport} />

            <h2 style={{ textAlign: 'center', marginTop: 40 }}>
              Month by Month Historical Invoice Amounts
            </h2>
            <HistoricalInvoiceAmountsTable invoiceTotals={invoiceTotals} />

            <h2 style={{ textAlign: 'center', marginTop: 40 }}>Key Dates</h2>
            <KeyDatesTable />
            {/* <InformativeAdvice /> */}
          </div>

          <WipReportPreviewDropdown onChange={val => setPreview(val)} />
        </WorkInProgressViewContainer>
      )}
      {preview === 'summary' && (
        <SummaryReport
          project={selectedProject}
          onReturn={() => setPreview(null)}
          month={month}
          person={person}
          wipData={wipReport}
          client={client}
          invoiceTotals={invoiceTotals}
        />
      )}
      {preview === 'detailed' && (
        <DetailedReport
          project={selectedProject}
          onReturn={() => setPreview(null)}
          month={month}
          person={person}
          wipData={wipReport}
          client={client}
          invoiceTotals={invoiceTotals}
        />
      )}
      {preview && (
        <div style={{ position: 'absolute', top: 0 }}>
          <IconButton onClick={() => setPreview(null)} name="go back">
            <ClearIcon />
          </IconButton>
        </div>
      )}
    </WorkInProgressContainer>
  ) : null
}

WorkInProgressView.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object),
}

export default WorkInProgressView

import React, { PureComponent } from 'react'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import { Container } from '@material-ui/core'
import DayPickerCalendar from '../DayPickerCalendar'

import { styles } from './style'

import { userSettingsPropTypes } from '../../../../constants/userSettings'

class CalendarDateSelector extends PureComponent {
  render() {
    return (
      <Container
        style={{ display: 'flex', flexDirection: 'column', width: 'fit-content', padding: 0 }}
      >
        <Container
          style={{
            border: '1px solid #979797',
            width: 'fit-content',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
            marginBottom: '10px',
          }}
        >
          <DayPickerCalendar
            changeDate={this.props.changeDate}
            selectedDate={this.props.selectedDate}
            isWeeklyView={!this.props.isDayViewPeriodSelected}
            timesheets={this.props.timesheets}
            userSettings={this.props.userSettings}
            userInfo={this.props.userInfo}
            currentMonth={this.props.currentMonth}
            updateCurrentMonth={this.props.updateCurrentMonth}
            navigateToPreviousMissingTimesheet={this.props.navigateToPreviousMissingTimesheet}
            navigateToNextMissingTimesheet={this.props.navigateToNextMissingTimesheet}
            navigateToEarlistMissingTimesheet={this.props.navigateToEarlistMissingTimesheet}
            selectedDelegateId={this.props.selectedDelegateId}
            delegateAccessList={this.props.delegateAccessList}
            pendingEntries={this.props.pendingEntries}
          />
        </Container>
      </Container>
    )
  }
}

CalendarDateSelector.propTypes = {
  changeDate: PropTypes.func.isRequired,
  selectedDate: PropTypes.object.isRequired,
  isDayViewPeriodSelected: PropTypes.bool.isRequired,
  timesheets: PropTypes.array.isRequired,
  userInfo: PropTypes.object.isRequired,
  currentMonth: PropTypes.instanceOf(Date).isRequired,
  updateCurrentMonth: PropTypes.func.isRequired,
  navigateToPreviousMissingTimesheet: PropTypes.func.isRequired,
  navigateToNextMissingTimesheet: PropTypes.func.isRequired,
  navigateToEarlistMissingTimesheet: PropTypes.func.isRequired,
  userSettings: userSettingsPropTypes,
  selectedDelegateId: PropTypes.string,
  delegateAccessList: PropTypes.array,
}

export default withStyles(styles)(CalendarDateSelector)

import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Paper } from '@material-ui/core'
import moment from 'moment'
import formatCurrency from '../../../utils/formatCurrency'

const CalendarContainer = styled(Paper)`
  width: 100%;
  padding: 16px;
  background: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`

const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 0.1fr);
  gap: 4px;
`

const DayCell = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.colors.nightshade.light};
  border-radius: 4px;
  background: ${props =>
    props.highlight ? props.theme.colors.nightshade.medium : props.theme.colors.paper};
  color: ${props => (props.highlight ? 'white' : 'black')};
`

const DayHeader = styled.div`
  text-align: center;
  font-weight: bold;
  color: #1976d2;
`

const CalendarFooter = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  gap: 2px;
`

const getMonthData = () => {
  const today = moment()
  const startDay = today.startOf('month').day()
  const daysInMonth = today.daysInMonth()
  const monthName = today.format('MMMM')
  return { daysInMonth, startDay, monthName }
}

const Calendar = ({ data, resume, project }) => {
  const { daysInMonth, startDay, monthName } = getMonthData()
  const weeks = useMemo(() => {
    const initialWeeks = []
    let dayIndex = 0

    while (dayIndex < daysInMonth) {
      const week = new Array(7).fill(null)
      for (let i = 0; i < 7; i++) {
        if (dayIndex === 0 && i < startDay) continue
        if (dayIndex < daysInMonth) {
          week[i] = { day: dayIndex + 1, value: data[dayIndex]?.value || 0 }
          dayIndex++
        }
      }
      initialWeeks.push(week)
    }
    return initialWeeks
  }, [data])

  return (
    <CalendarContainer>
      <div
        style={{ textAlign: 'center', fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '8px' }}
      >
        {monthName}
      </div>
      <CalendarGrid>
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <DayHeader key={day}>{day}</DayHeader>
        ))}
        {weeks.map((week, wi) =>
          week.map((day, di) => (
            <DayCell key={`${wi}-${di}`} highlight={day?.value}>
              {day?.day && (
                <>
                  <span style={{ fontSize: '0.9rem', fontWeight: '500' }}>{day.day}</span>
                  {day.value > 0 && (
                    <span style={{ fontSize: '0.8rem' }}>{day.value.toFixed(2)}</span>
                  )}
                </>
              )}
            </DayCell>
          )),
        )}
      </CalendarGrid>
      <CalendarFooter>
        <p>Month Total Hours &nbsp; {resume.totalHours.toFixed(2)}</p>
        <p>Month Total Amount &nbsp; {formatCurrency(resume.totalRate, project.CurrencyIsoCode)}</p>
      </CalendarFooter>
    </CalendarContainer>
  )
}

export default Calendar

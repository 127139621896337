import React from 'react'
import _, { get } from 'lodash'
import PropTypes from 'prop-types'
import DayPicker from 'react-day-picker'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import red from '@material-ui/core/colors/red'
import moment from 'moment'

import Button from '@material-ui/core/Button'
import timeUtils from '../../../../utils/timeUtils'
import DayPickerNavbar from '../DayPickerNavbar'
import CustomTooltip from '../../../../components/CustomTooltip'
import {
  ButtonContainer,
  DayPickerContainer,
  TodayButton,
  MissingButton,
  TextContainer,
} from '../../style'
import { DayPickerArrow } from '../styles'
import DayTooltip from '../DayTooltip'

import eventUtils from '../../../../utils/eventUtils'
import {
  getNumberOfMissingTimeSheets,
  isPreviousArrowHidden,
  isNextArrowHidden,
  getMissingNavigationStartDate,
  getNumberOfSubmittedTimeSheets,
  getNumberOfSubmittedHours,
  getUnsubmittedTimesheetsWithHours,
  getNumberOfUnsubmittedHours,
} from '../../../../utils/timesheetUtils'
import { userSettingsPropTypes } from '../../../../constants/userSettings'
import { ksTheme } from '../../../../theme'

const DayPickerCalendar = props => {
  const { startDate } =
    _.find(props.delegateAccessList, { _id: props.selectedDelegateId }) || props.userInfo

  const handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) return
    props.changeDate(day)
  }

  const handleTodayButtonClick = () => {
    props.changeDate(new Date())
  }

  const getWeeklyViewModifiers = () => {
    const { from, to } = timeUtils.getWeekRange(props.selectedDate)
    return {
      selectedRange: { from, to },
      selectedRangeStart: from,
      selectedRangeEnd: to,
      future: {
        after: new Date(),
      },
    }
  }

  const getDailyViewModifiers = () => {
    return {
      selected: props.selectedDate,
      future: {
        before: new Date(moment(startDate).add(1, 'day')),
        after: new Date(),
      },
    }
  }

  const getModifiers = () => {
    if (props.isWeeklyView) {
      return getWeeklyViewModifiers()
    }
    return getDailyViewModifiers()
  }

  const renderDay = timesheetDictionaryByDates => day => {
    const formattedDayYYYYMMDD = timeUtils.formatDateToYYYYMMDD(day)
    const timesheet = timesheetDictionaryByDates[formattedDayYYYYMMDD]
    return <DayTooltip date={day} timesheet={timesheet} pendingEntries={props.pendingEntries} />
  }

  const renderNavbar = propsFromPicker => (
    <DayPickerNavbar
      firstMonthRange={props.userSettings.firstMonthRange}
      lastMonthRange={props.userSettings.lastMonthRange}
      userStartDate={props.userInfo.startDate}
      updateCurrentMonth={props.updateCurrentMonth}
      {...propsFromPicker}
    />
  )

  const renderButtons = () => {
    const missingNavigationStartDate = getMissingNavigationStartDate(props.userInfo.startDate)
    const numberOfMissingTimesheets = getNumberOfMissingTimeSheets(
      props.timesheets,
      missingNavigationStartDate,
    )

    const numberOfSubmittedHours = getNumberOfSubmittedHours(props.timesheets)

    const unsubmittedTimesheetsWithHours = getUnsubmittedTimesheetsWithHours(props.timesheets)

    const numberOfUnsubmittedHours = getNumberOfUnsubmittedHours(unsubmittedTimesheetsWithHours)

    const numberOfSubmittedTimeSheets = getNumberOfSubmittedTimeSheets(props.timesheets)

    const missingTimesheetsText = `${numberOfMissingTimesheets} Missing`
    const redArrorwStyle = { fontSize: 24, color: red[500] }
    return (
      <>
        <ButtonContainer>
          <CustomTooltip title="Previous month with missing timesheets">
            <DayPickerArrow
              hide={isPreviousArrowHidden(
                props.timesheets,
                props.currentMonth,
                missingNavigationStartDate,
              )}
              onClick={props.navigateToPreviousMissingTimesheet}
            >
              <ChevronLeftIcon style={redArrorwStyle} />
            </DayPickerArrow>
          </CustomTooltip>
          <TextContainer>
            {numberOfMissingTimesheets > 0 && (
              <>
                <MissingButton variant="text" onClick={props.navigateToEarlistMissingTimesheet}>
                  {missingTimesheetsText}
                </MissingButton>
                |
              </>
            )}
            <TodayButton onClick={handleTodayButtonClick}>Today</TodayButton>|
            <Button
              style={{
                textTransform: 'none',
                fontSize: 12,
                color: ksTheme.colors.kellyGreen.medium,
                cursor: 'default',
              }}
            >
              {numberOfSubmittedTimeSheets} Submitted
            </Button>
          </TextContainer>
          <CustomTooltip title="Next month with missing timesheets">
            <DayPickerArrow
              hide={isNextArrowHidden(props.timesheets, props.currentMonth)}
              onClick={props.navigateToNextMissingTimesheet}
            >
              <ChevronRightIcon style={redArrorwStyle} />
            </DayPickerArrow>
          </CustomTooltip>
        </ButtonContainer>
        <ButtonContainer>
          <TextContainer>
            <Button
              style={{
                textTransform: 'none',
                fontSize: 12,
                color: '#a69b00',
                cursor: 'default',
              }}
            >
              {unsubmittedTimesheetsWithHours.length} With unsubmitted hours
            </Button>
          </TextContainer>
        </ButtonContainer>
        <ButtonContainer style={{ marginBottom: 10 }}>
          <TextContainer>
            <Button
              style={{
                textTransform: 'none',
                fontSize: 12,
                color: '#a69b00',
                cursor: 'default',
              }}
            >
              {numberOfUnsubmittedHours} Unsubmitted hours
            </Button>
            <Button
              style={{
                textTransform: 'none',
                fontSize: 12,
                color: ksTheme.colors.kellyGreen.medium,
                cursor: 'default',
              }}
            >
              {numberOfSubmittedHours} Submitted hours
            </Button>
          </TextContainer>
        </ButtonContainer>
      </>
    )
  }

  const disablePastDays = () => {
    return { before: new Date(moment(startDate).add(1, 'day')) }
  }

  const timesheetDictionaryByDates = eventUtils.getTimesheetDictionaryByDate(props.timesheets)

  return (
    <DayPickerContainer>
      <DayPicker
        canChangeMonth
        initialMonth={props.selectedDate}
        month={props.currentMonth}
        modifiers={getModifiers()}
        disabledDays={disablePastDays()}
        onDayClick={handleDayClick}
        navbarElement={renderNavbar}
        captionElement={() => null}
        renderDay={renderDay(timesheetDictionaryByDates)}
        firstDayOfWeek={1}
      />
      {renderButtons()}
    </DayPickerContainer>
  )
}

DayPickerCalendar.propTypes = {
  changeDate: PropTypes.func.isRequired,
  selectedDate: PropTypes.object.isRequired,
  isWeeklyView: PropTypes.bool.isRequired,
  timesheets: PropTypes.array.isRequired,
  userInfo: PropTypes.object.isRequired,
  userSettings: userSettingsPropTypes,
  currentMonth: PropTypes.instanceOf(Date).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  updateCurrentMonth: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  navigateToPreviousMissingTimesheet: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  navigateToNextMissingTimesheet: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  navigateToEarlistMissingTimesheet: PropTypes.func.isRequired,
  selectedDelegateId: PropTypes.string,
  delegateAccessList: PropTypes.array,
}

export default DayPickerCalendar

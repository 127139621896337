import moment from 'moment'
import { flatten } from 'lodash'

export const getLastMonthAndCurrentRanges = () => {
  const today = moment.utc()
  const thisMonthStart = moment.utc(today).startOf('month')
  const thisMonthEnd = moment.utc(today).endOf('month')
  const lastMonthStart = moment
    .utc(thisMonthStart)
    .subtract(1, 'month')
    .startOf('month')
  const lastMonthEnd = moment.utc(lastMonthStart).endOf('month')

  return {
    lastMonth: { start: lastMonthStart.toDate(), end: lastMonthEnd.toDate() },
    thisMonth: { start: thisMonthStart.toDate(), end: thisMonthEnd.toDate() },
  }
}
export const groupTimeEntries = (timeEntries, timesheets, weeks) => {
  const timesheetsMap = timesheets.reduce((acc, timesheet) => {
    acc[timesheet.repliconId] = timesheet.approvalStatus === 'Approved'
    return acc
  }, {})

  // Group time entries by project --> week --> person
  // {'projectId: { week: { personId: { entries: [] } } } }

  return timeEntries.reduce((acc, entry) => {
    if (entry.approveState.status !== 'pending') {
      return acc
    }
    const week = weeks.find(currentWeek => {
      const entryDate = moment.utc(entry.entryDate)
      return entryDate.isBetween(currentWeek.start, currentWeek.end, null, '[]')
    })

    if (!week) return acc

    const timesheet = timesheetsMap[entry.repliconTimesheetId]
    if (!timesheet) return acc
    if (!acc[entry.projectId]) {
      acc[entry.projectId] = {}
    }

    if (!acc[entry.projectId][week.stringWeek]) {
      acc[entry.projectId][week.stringWeek] = {}
    }

    if (!acc[entry.projectId][week.stringWeek][entry.personId]) {
      acc[entry.projectId][week.stringWeek][entry.personId] = []
    }

    acc[entry.projectId][week.stringWeek][entry.personId].push({
      ...entry,
      weekDay: moment.utc(entry.entryDate).format('dddd'),
    })

    return acc
  }, {})
}
// Returns the number of weekdays (Monday-Friday) in a given month.
// Month is 0-indexed (0 for January, 11 for December).
export function getNumberOfWeekdaysInMonth(year, month) {
  const startOfMonth = moment([year, month]).startOf('month')
  const endOfMonth = moment([year, month]).endOf('month')
  let count = 0
  for (let day = startOfMonth.date(); day <= endOfMonth.date(); day++) {
    const currentDay = moment([year, month, day])
    // moment().day(): 0 = Sunday, 6 = Saturday
    if (currentDay.day() !== 0 && currentDay.day() !== 6) {
      count++
    }
  }
  return count
}

// Returns the number of weekdays in the week that fall within the provided month and year.
export function getWeekdaysInWeekForMonth(week, year, month) {
  const startOfWeek = moment(week).startOf('week')
  const endOfWeek = moment(week).endOf('week')
  let count = 0
  for (let day = startOfWeek.clone(); day.isSameOrBefore(endOfWeek, 'day'); day.add(1, 'day')) {
    if (day.year() === year && day.month() === month && day.day() !== 0 && day.day() !== 6) {
      count++
    }
  }
  return count
}

// Instead of calculating based on whole weeks, we now calculate based on weekdays.
export const calculateBudgetAndPacingInWeek = (week, project, timeEntries, expenses, rates) => {
  if (!week || !project || !timeEntries || !rates) return {}

  const totalWeekdaysInMonth = getNumberOfWeekdaysInMonth(week.year(), week.month())
  const dailyBudget = project.Budget__c / totalWeekdaysInMonth
  const weekBudget = (dailyBudget * 5).toFixed(2)

  const plainEntries = flatten(Object.values(timeEntries))
  const projectRates = rates[project._id]
  const actualEntriesUsed = plainEntries.reduce((acc, entry) => {
    return acc + projectRates[entry.personId] * entry.durationInHours
  }, 0)

  const actualExpensesUsed = expenses.reduce((acc, expense) => {
    if (expense.billingStatus === 'Billable') {
      return acc + expense.amount
    }
    return acc
  }, 0)

  const actualUsed = (actualEntriesUsed + actualExpensesUsed).toFixed(2)

  const actualPacing = ((actualUsed / weekBudget) * 100).toFixed(2)

  return {
    weekBudget,
    actualPacing,
    actualUsed,
  }
}

import gql from 'graphql-tag'

export const CREATE_ANALYTICS_INITIAL_SYNC_TIME_TRACK = gql`
  mutation(
    $timesheetDataLoadTime: Float!
  ) {
    analytics_initialSyncTime_track(
      timesheetDataLoadTime: $timesheetDataLoadTime
    )
  }
`

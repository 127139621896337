import styled from 'styled-components'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { FormLabel, Select } from '@material-ui/core'

export const StyledFormLabel = styled(FormLabel)`
    // color: ${({ theme }) => theme.colors.gray[700]};

  &.MuiFormLabel-root.Mui-focused {
    color: ${({ theme }) => theme.colors.gray[900]};
  }
`

export const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiIconButton-label {
    color: ${({ theme }) => theme.colors.nightshade.medium};
  }
`
export const StyledSelect = styled(Select)`
  color: ${({ theme }) => theme.colors.gray[900]};
  font-size: 1rem;
  margin-top: 1rem;
`

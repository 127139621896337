import gql from 'graphql-tag'

export const GET_PROJECTS = gql`
  query {
    getProjects {
      Address
      Matter_Name
      billable
      client
      name
      projectCode
      repliconId
      taskIds
      isOpenForTimeEntries
      _id
      StageName
      lead
      isFixedBill
      Budget__c
      CurrencyIsoCode
    }
  }
`

export const GET_RATES_BY_PROJECT_LEAD = gql`
  query getRatesByProjectLead($projectLeadId: String!) {
    getRatesByProjectLead(projectLeadId: $projectLeadId) {
      rates
    }
  }
`
export const GET_EXPENSES_BY_PROJECT_IDS = gql`
  query getExpensesByProjectIds($projectIds: String!) {
    getExpensesByProjectIds(projectIds: $projectIds) {
      expenses
    }
  }
`

export const GET_WIP_SUMMARY_REPORT_DOC = gql`
  query getWipSummaryReportDoc($project: ProjectInput!, $wipData: WipDataInput!, $person: PersonInput!) {
    getWipSummaryReportDoc(project: $project, wipData: $wipData, person: $person)
  }
`

export const GET_WIP_DETAILED_REPORT_DOC = gql`
  query getWipDetailedReportDoc($project: ProjectInput!, $wipData: WipDataInput!, $person: PersonInput!) {
    getWipDetailedReportDoc(project: $project, wipData: $wipData, person: $person)
  }
`

export const GET_WIP_REPORTS_BY_PROJECT_IDS = gql`
  query getWipReportsByProjectIds($projectIds: String!, $months: String!) {
    getWipReportsByProjectIds(projectIds: $projectIds, months: $months) {
      projectId
      monthlyWipReports {
        month
        submittedHours {
          staffHours
          expertHours
          staffRate
          expertRate
        }
        submittedWriteOffs
        submittedExpenses {
          staffExpenses
          expertExpenses
        }
        totalFeesAndExpenses
        wipPacing
        hoursDetails {
          personName
          date
          comment
          hours
          total
        }
        expensesDetails {
          personName
          date
          description
          account
          cost
        }
      }
    }
  }
`

export const GET_PROJECT_INVOICES = gql`
  query getProjectInvoices($projectId: String!) {
    getProjectInvoices(projectId: $projectId) {
      _id
      due_date
      name
      paid
      dateActivated
      sections {
        amount
      }
    }
  }
`

export const GET_FILTERED_TIME_ENTRIES = gql`
  query {
    getTimeEntries {
      projectId
      timeEntries {
        _id
        sourceId
        repliconTimesheetId
        originalPersonId
        personId
        originalRepliconPersonId
        repliconPersonId
        comment
        entryDate
        originalEntryDate
        durationInHours
        duration {
          hours
          minutes
          seconds
          milliseconds
          microseconds
        }
        billable
        estimatedRunRate
        projectId
        repliconProjectId
        repliconProjectTaskId
        status
        originalDurationInHours
        originalComment
        originalProjectId
        source
        isClientProject
        quickbooksInvoiceId
        invoicingParent
        invoicingConstituents
        start
        end
        splitParent
        splitChildren
        referenceId
        referenceType
        createdBy
        creationWay
        eventTagId
        approveState
      }
    }
  }
`

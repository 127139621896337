import moment from 'moment'
import { get, filter } from 'lodash'

import { timesheets } from '@keystone-labs/seed-data'
import { APPROVED } from '../../constants/timesheetStatus'
import config from '../../config'
import { convertDateToLocalTimezone } from '../../server/utils/date'

export const getNumberOfMissingTimeSheets = (
  timesheets,
  startDate = config.TIMESHEETS_FETCH_LOWEST_LIMIT,
  endDate = moment().utc(),
) => {
  const utcStartDate = moment(startDate).utc()
  const utcEndDate = isEndDateToday(endDate)
    ? moment(endDate)
        .utc()
        .startOf('day')
    : moment(endDate)
        .utc()
        .add(1, 'day')
        .startOf('day')
  const days = utcEndDate.diff(utcStartDate, 'days')
  const timesheetsBetweenRange = getSubmittedTimesheetsBetweenDates(
    timesheets,
    utcStartDate,
    utcEndDate,
  )

  return days - timesheetsBetweenRange.length
}

export const getNumberOfSubmittedTimeSheets = timesheets => {
  return timesheets.filter(timesheet => get(timesheet, 'approvalStatus') === APPROVED).length
}

export const getNumberOfSubmittedHours = timesheets => {
  return timesheets.reduce((totalHours, timesheet) => totalHours + timesheet.totalHours, 0)
}

export const getUnsubmittedTimesheetsWithHours = timesheets => {
  return timesheets.filter(
    timesheet => timesheet.approvalStatus === 'Open' && timesheet.totalHours > 0,
  )
}

export const getNumberOfUnsubmittedHours = unsubmittedTimesheetsWithHours => {
  return unsubmittedTimesheetsWithHours.reduce(
    (totalHours, timesheet) => totalHours + timesheet.totalHours,
    0,
  )
}

const getSubmittedTimesheetsBetweenDates = (timesheets, startDate, endDate) => {
  return filter(timesheets, timesheet => {
    if (get(timesheet, 'approvalStatus') !== APPROVED) return false
    const timesheetDate = moment(timesheet.startDate).utc()
    const endDateToCompare = isEndDateToday(timesheetDate)
      ? moment(endDate)
          .utc()
          .subtract(1, 'day')
          .endOf('day')
      : endDate

    return (
      timesheetDate.isSame(startDate) ||
      timesheetDate.isBetween(startDate, endDate) ||
      timesheetDate.isSame(endDateToCompare)
    )
  })
}

const isEndDateToday = endDate =>
  moment()
    .utc()
    .diff(endDate, 'day') === 0

export const isPreviousArrowHidden = (timesheets, selectedDate, lowestLimit) => {
  const endDate = moment(selectedDate)
    .utc()
    .subtract(1, 'months')

  if (endDate.isBefore(lowestLimit, 'month')) return true

  const endOfMonthEndDate = endDate.endOf('month')
  const timesheetsBetweenDates = getSubmittedTimesheetsBetweenDates(
    timesheets,
    lowestLimit,
    endOfMonthEndDate,
  )
  const numberOfMissingTimesheets = getNumberOfMissingTimeSheets(
    timesheetsBetweenDates,
    lowestLimit,
    endDate,
  )

  return numberOfMissingTimesheets === 0
}

export const isNextArrowHidden = (timesheets, selectedDate, currentDate = new Date()) => {
  const endOfSelectedMonth = moment(selectedDate)
    .utc()
    .endOf('month')
  const isCurrentMonth = endOfSelectedMonth.isAfter(moment(currentDate).utc())

  if (isCurrentMonth) return true

  const startDate = moment(selectedDate)
    .utc()
    .add(1, 'months')
    .startOf('month')

  return getNumberOfMissingTimeSheets(timesheets, startDate) === 0
}

const doesMonthHaveMissingTimesheets = (missingNavigationStartDate, currDate, timesheets) => {
  if (moment(currDate).isAfter(moment())) return false
  if (moment(missingNavigationStartDate).isAfter(currDate)) return false

  const firstDayOfMonth = moment(currDate).startOf('month')
  const lastDayOfMonth = moment(firstDayOfMonth).endOf('month')
  const daysInMonth = lastDayOfMonth.daysInMonth()
  const submittedTimesheetsBetweenDates = getSubmittedTimesheetsBetweenDates(
    timesheets,
    firstDayOfMonth,
    lastDayOfMonth,
  )
  const numberOfSubmitted = submittedTimesheetsBetweenDates.length
  const missingTimesheetsInMonth = daysInMonth - numberOfSubmitted
  return missingTimesheetsInMonth > 0
}

const createWalker = ({ nextMonthCalculator, isLookingInFuture }) => {
  return args => searchForMissingTimesheetMonth({ nextMonthCalculator, isLookingInFuture, ...args })
}

const searchForMissingTimesheetMonth = ({
  nextMonthCalculator,
  isLookingInFuture,
  missingNavigationStartDate,
  searchingDate,
  timesheets,
}) => {
  const date = moment(convertDateToLocalTimezone(searchingDate))
  const isDateInRange = getIsDateInRange(isLookingInFuture, date, missingNavigationStartDate)

  if (!isDateInRange) return date.toDate()

  if (doesMonthHaveMissingTimesheets(missingNavigationStartDate, date, timesheets))
    return new Date(date)

  const nextStepMonth = nextMonthCalculator(searchingDate)
  return searchForMissingTimesheetMonth({
    nextMonthCalculator,
    isLookingInFuture,
    missingNavigationStartDate,
    searchingDate: nextStepMonth,
    timesheets,
  })
}

const getIsDateInRange = (isLookingInFuture, date, missingNavigationStartDate) => {
  if (isLookingInFuture) {
    return moment()
      .endOf('month')
      .isAfter(moment(date).endOf('month'))
  }
  return moment(date).isAfter(missingNavigationStartDate)
}

// getPreviousMonth : nextMonthCalculator
const getPreviousMonth = date => moment(date).subtract(1, 'months')
const getNextMonth = date => moment(date).add(1, 'months')

export const searchForPreviousClosestMissingTimesheetMonth = createWalker({
  nextMonthCalculator: getPreviousMonth,
  isLookingInFuture: false,
})
export const searchForNextClosestMissingTimesheetMonth = createWalker({
  nextMonthCalculator: getNextMonth,
  isLookingInFuture: true,
})

export const getMissingNavigationStartDate = (
  userCreatedDate,
  lowestTimeLimit = config.TIMESHEETS_FETCH_LOWEST_LIMIT,
) => (moment(userCreatedDate).isAfter(lowestTimeLimit) ? userCreatedDate : lowestTimeLimit)

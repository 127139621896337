import React from 'react'
import styled from 'styled-components'
import {
  Container as MuiContainer,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import _ from 'lodash'
import KsLogo from '../../../assets/ks-logo.png'
import { getWipSummaryReportDoc } from '../../../entities/project/service'

const StyledContainer = styled(MuiContainer)`
  background: #fff;
  padding: 20px;
  border: 1px solid #ddd;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
`

const Title = styled(Typography)`
  background-color: ${props => props.theme.colors.paper};
  padding: 5px 10px;
  border-radius: 5px;
`

const Section = styled.section`
  margin-top: 20px;
`

const StyledButton = styled(Button)`
  background-color: ${props => props.theme.colors.nightshade.medium};
  color: ${props => props.theme.colors.paper};

  &:hover {
    background-color: ${props => props.theme.colors.nightshade.dark};
  }

  border-radius: 40px;
`
const downloadDocx = (base64String, fileName = 'wip-report.docx') => {
  // Convert base64 to a byte array
  const byteCharacters = atob(base64String)
  const byteNumbers = new Array(byteCharacters.length)
    .fill(0)
    .map((_, i) => byteCharacters.charCodeAt(i))

  // Convert byte array to a Blob
  const byteArray = new Uint8Array(byteNumbers)
  const blob = new Blob([byteArray], {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  })

  // Create a downloadable URL
  const blobUrl = URL.createObjectURL(blob)

  // Create an invisible anchor element and trigger the download
  const a = document.createElement('a')
  a.href = blobUrl
  a.download = fileName
  document.body.appendChild(a)
  a.click()

  // Cleanup: Remove the anchor tag and revoke the URL
  document.body.removeChild(a)
  URL.revokeObjectURL(blobUrl)
}

const SummaryReport = ({ project, month, person, wipData = {}, client, invoiceTotals = [] }) => {
  const financialSummaryData = [
    { label: 'Staff Fees', value: wipData.submittedHours.staffRate },
    { label: 'Expert Fees', value: wipData.submittedHours.expertRate },
    { label: 'Fee Write Offs', value: wipData.submittedWriteOffs, isIndented: true },
    {
      label: 'Total Expenses(Costs & Disbursements)',
      value: wipData.totalFeesAndExpenses,
      isBold: true,
    },
    {
      label: 'Expense Write Offs',
      value: wipData.submittedExpenses.expertExpenses,
    },
  ]

  const wipTotalData = [
    { label: 'Total Fees', value: wipData.totalFeesAndExpenses },
    { label: 'Total Expenses', value: wipData.submittedExpenses.staffExpenses },
    { label: 'Discounts', value: wipData.submittedWriteOffs },
    { label: 'WIP Total', value: wipData.totalFeesAndExpenses, isBold: true },
    { label: 'Budget Total', value: project.Budget__c, isIndented: true },
    { label: 'Pacing', value: wipData.wipPacing, isIndented: true },
  ]

  return (
    <StyledContainer maxWidth="md">
      <Header>
        <Title variant="h6">Summary WIP - Cumulative ({month})</Title>
        <StyledButton
          variant="contained"
          onClick={async () => {
            const wipReportDoc = await getWipSummaryReportDoc(
              client,
              _.omit(project, ['__typename']),
              wipData,
              person
            )
            downloadDocx(wipReportDoc)
          }}
        >
          Download Report
        </StyledButton>
      </Header>
      <Section
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <img
            alt="keystone logo"
            src={KsLogo}
            style={{
              width: '300px',
              height: 'auto',
            }}
          />
        </div>

        <div
          style={{
            width: '200px',
          }}
        >
          <Typography>
            <strong>Work in Progress Report</strong>
          </Typography>
          <Typography
            style={{
              fontStyle: 'italic',
              fontWeight: 200,
              marginTop: '10px',
            }}
          >
            Everything included in this report is considered draft until it is finalized and
            invoiced.
          </Typography>
        </div>
      </Section>

      <Section>
        <Typography variant="body1">
          <strong>Bill to:</strong> {project.client}
        </Typography>
        <Typography variant="body1">
          <strong>Attn:</strong> {person.fullName}
        </Typography>
        <Typography variant="body1">
          <strong>Address:</strong> {project.Address}
        </Typography>
      </Section>
      <Section>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Reporting Period</strong>
              </TableCell>
              <TableCell>
                <strong>Matter Name / Number</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>01/03/2025 - 31/03/2025</TableCell>
              <TableCell>{project.name}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Section>
      <Section>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Financial Summary</strong>
              </TableCell>
              <TableCell align="right">
                <strong>Line Total</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {financialSummaryData.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  {item.isIndented ? (
                    <p style={{ marginLeft: '10px' }}>{item.label}</p>
                  ) : (
                    item.label
                  )}
                </TableCell>
                <TableCell align="right">
                  {item.isBold ? <strong>{item.value}</strong> : item.value}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell />
              <TableCell align="right">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell align="right" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {wipTotalData.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {item.isIndented ? (
                            <p style={{ marginLeft: '20px' }}>{item.label}</p>
                          ) : (
                            item.label
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {item.isBold ? <strong>{item.value}</strong> : item.value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              {invoiceTotals.map(invoice => (
                <TableCell>{invoice.month}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Invoice Amount</TableCell>
              {invoiceTotals.map(invoice => (
                <TableCell>{invoice.total}</TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </Section>
      <Section
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <Typography style={{ marginLeft: '40px' }}>
          <strong> Summary Context</strong>
        </Typography>
        <Divider style={{ width: '100%' }} />
        <Typography style={{ marginLeft: '40px', marginRight: '40px' }}>
          <strong>
            This section will be free form, editable section which allows project leaders to enter
            free form text to explain the current deliverables, their status, and next steps. They
            can call out any dependencies or other context which is required to keep the client
            informed of the situation.
          </strong>
        </Typography>
      </Section>
    </StyledContainer>
  )
}

export default SummaryReport

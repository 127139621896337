import React from 'react';
import { TableCell } from '@material-ui/core';

const ColoredCell = ({ children, backgroundColor, style = {} }) => {
  return (
    <TableCell
      style={{
        backgroundColor: backgroundColor || 'transparent',
        ...style,
      }}
    >
      {children}
    </TableCell>
  );
};

export default ColoredCell;